import { JarvisAuthProvider } from '@jarvis/web-http';

export default function getLocalization(): {
  country?: string;
  language?: string;
  authProvider?: JarvisAuthProvider;
} {
  const localization = window?.Shell?.v1?.localization;
  const country = localization?.country?.toUpperCase() || 'US';
  const language = localization?.language?.toLowerCase() || 'en';
  return { country, language };
}
