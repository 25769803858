import { getRandomNumber } from './getRandomNumber';

function getCookie(name: string): string | null {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function setCookie(name: string, value: string): void {
  const d = new Date();
  d.setTime(d.getTime() + 6048e5);
  const expires = '; expires=' + d.toUTCString();
  document.cookie = name + '=' + value + expires + '; path=/';
}

function checkCondition(e: number, h: string, f: string): boolean {
  let a: string | string[] = getCookie(f);
  if (a) {
    a = a.split(':');
  } else if (e !== 100) {
    if (h === 'v') {
      e = getRandomNumber(0, 100) >= e / 100 ? 0 : 100;
    }
    a = [h, e.toString(), '0'];
    setCookie(f, a.join(':'));
  } else {
    return true;
  }
  const c = parseInt(a[1]);
  let count = 0;
  if (c === 100) return true;
  switch (a[0]) {
    case 'v':
      return false;
    case 'r':
      count = parseInt(a[2]) % Math.floor(100 / c);
      a[2] = (parseInt(a[2]) + 1).toString();
      setCookie(f, a.join(':'));
      return count === 0;
  }
  return true;
}

export { getCookie, setCookie, checkCondition };
