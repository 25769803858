import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { URLS } from '../urlConfig';
import { Stack } from '@jarvis/web-stratus-client';

export const createApolloClient = (authToken: string, stack: Stack) => {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : ''
      }
    };
  });

  const httpLink = createHttpLink({
    uri: URLS[stack].API_URLS.APOLLO_GRAPGQL
  });

  const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache()
  });

  return client;
};
