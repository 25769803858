import { FulfillmentClient } from 'src/services/FulfillmentClient';
import { Order } from 'src/types/order';

export const isDeviceShippedOrDelivered = async (
  fulfilmentClient: FulfillmentClient,
  entity: any
) => {
  const entityFulfilment =
    (await fulfilmentClient.getFulfilmentOrderByFulfillmentOrderId(
      entity.fulfillment.value.fulfillmentOrderId
    )) as Order[];

  let foundPart;

  const enrollmentOrder = entityFulfilment.filter(
    (item) => item.productType === 'hardware'
  );
  const possible = enrollmentOrder[0].parts.find(
    (part) => part.modelSku === entity.product.value.productSku
  );
  if (possible) foundPart = possible;

  if (!foundPart) return false;
  const statuses = foundPart.history?.map((old) => old.status) || [];
  statuses.push(foundPart.status);
  return statuses.includes('shipped');
};
