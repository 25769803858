import CancellationImage from './CancellationImage.png';
import PaperPlaceHolderImage from './paper-placeholder.svg';
import PaperPrinterPlaceHolderImage from './paper-printer.svg';
import PaperProgressImage from './paper-progress.svg';
import InkPaperImage from './InkPaperImage.png';
const Images = {
  CancellationImage,
  PaperPlaceHolderImage,
  PaperPrinterPlaceHolderImage,
  PaperProgressImage,
  InkPaperImage
};

export default Images;
