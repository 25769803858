import type { InkPlanInfo } from '../types/planInfo';
import type { ProductResult } from '../types/graphql';
const findInkPlan = (
  results: ProductResult,
  sku: string
): InkPlanInfo | null => {
  const printerBundle = results?.products?.items[0];
  const inkBundle = {
    options: printerBundle.items.reduce((accumulator, item) => {
      const filtered = item.options.filter(
        (option) => option.product.hp_product_type_label === 'instantInk'
      );
      return accumulator.concat(filtered);
    }, [])
  };
  const inkPlan = inkBundle?.options?.find((plan) => plan.product.sku === sku);

  if (!inkPlan) return null;
  return {
    pages: inkPlan.product?.hp_pages_per_month ?? 0
  };
};
const findOptionInkPlan = (results: any, sku: string): InkPlanInfo | null => {
  const inkBundle = results?.entities[0]?.edit?.filter(
    (item) => item?.product?.value?.productType === 'instantInk'
  );
  const inkPlan = inkBundle?.find(
    (plan) => plan?.product?.value?.productSku === sku
  );
  if (!inkPlan) return null;
  return {
    pages: inkPlan?.product?.value?.pagesPerMonth ?? 0
  };
};
export { findInkPlan, findOptionInkPlan };
