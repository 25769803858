import React from 'react';
import { RemorsePeriod } from '../RemorsePeriod';
import { NotInRemosePeriod } from '../NotInRemosePeriod';
import { Line, RemorseSubTitle, RemorseTitle, TaxText } from './styles';

const RemorseComponent = ({
  inRemorse,
  t,
  canceledDate,
  isLoading,
  costValue,
  props,
  after10days,
  after10DaysOfBilling
}) => {
  return (
    <>
      <RemorseTitle>
        {t('SecondComponent.CardLabelText', 'Here’s what happens next')}
      </RemorseTitle>
      <RemorseSubTitle>
        {t(
          'SecondComponent.CardText2',
          'We’ve also emailed you a copy of this schedule for reference.'
        )}
      </RemorseSubTitle>
      <Line />
      {inRemorse ? (
        <RemorsePeriod
          t={t}
          canceledDate={canceledDate}
          isLoading={isLoading}
          filteredCost={costValue}
          props={props}
          after10days={after10days}
          isInRemorse={inRemorse}
        />
      ) : (
        <NotInRemosePeriod
          t={t}
          canceledDate={canceledDate}
          isLoading={isLoading}
          lastDayToReturnPrinter={after10DaysOfBilling}
          filteredCost={costValue}
          props={props}
          isInRemorse={inRemorse}
        />
      )}
      <TaxText>
        * {t('FirstComponent.Description.Content7', 'Taxes may be applied.')}
      </TaxText>
    </>
  );
};

export default RemorseComponent;
