import {
  StratusClient,
  Stack
} from '@jarvis/web-stratus-client/dist/src/clients/stratus-client';
import { JarvisAuthProvider } from '@jarvis/web-http';

import { CommerceBillingCycle } from 'src/types/billingCyclePeriods';
import { URLS } from '../services/urlConfig';
export class CommerceBillingCycleClient extends StratusClient {
  apiVersion = '1';
  timeout = 0;

  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(URLS[stack].API_URLS.COMMERCE_BILLING_CYCLE_BASE_URL, authToken);
  }

  async getBillingDetails(commerceId): Promise<CommerceBillingCycle> {
    return this.jarvisWebHttpInstance
      .get({
        url: `/subscriptions/${commerceId}/details`
      })
      .then(({ data: billingDate }) => billingDate);
  }
}
