import moment from 'moment';

const dateFormatter = (date: string, format: string = 'MMM DD, YYYY') => {
  return moment(date).format(format);
};

const addDays = (date: string, days: number = 10) => {
  return moment(date).add(days, 'days').toDate();
};

async function getOrderDate(
  contents,
  setCanceledData,
  setCanceledDate,
  setAfter10days
) {
  const pendingCancellation = contents?.filter(
    (content) =>
      content.type === 'cancellation' &&
      content.reason.type !== 'warrantyRepairReplaement'
  );

  setCanceledData(pendingCancellation?.[0]);
  const cancellationStarted =
    pendingCancellation?.[0]?.value?.cancellationStarted;

  setCanceledDate(dateFormatter(cancellationStarted.split('T')[0]));

  // Add 10 days
  const after10daysDate = addDays(cancellationStarted.split('T')[0]);
  setAfter10days(dateFormatter(after10daysDate.toString()));
}

const get10DaysBillingDate = (
  cancellationBillingDate: string,
  setAfter10DaysOfBilling
) => {
  const after10DaysOfBilling = addDays(cancellationBillingDate);
  setAfter10DaysOfBilling(dateFormatter(after10DaysOfBilling.toString()));
};

const getTrialPeriodData = (
  contents,
  cancellationBillingDate: string,
  setInRemorse,
  setAfter10DaysOfBilling
) => {
  const withinTrialPeriodData = contents.filter(
    (content) => content.reason.type === 'cancellationWithinTrialPeriod'
  );

  if (withinTrialPeriodData?.length > 0) {
    setInRemorse(true);
  } else {
    get10DaysBillingDate(cancellationBillingDate, setAfter10DaysOfBilling);
  }
};

export { getTrialPeriodData, getOrderDate };
