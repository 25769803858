import styled from 'styled-components';
import { tokens } from '@veneer/tokens';
const { white, gray9, gray12, gray3 } = tokens.color;
const {
  size4: layoutSize4,
  size2: layoutSize2,
  size3: layoutSize3,
  size6: layoutSize6,
  size8: layoutSize8
} = tokens.layout;

const { size1, size5, lineHeight2, lineHeight5, family0 } = tokens.typography;
export const CardContainer = styled.div`
  padding: ${layoutSize8};
  border-radius: ${layoutSize4};
  border: 1px solid #adadad;
  background: ${white};
`;
export const CardTitle = styled.p`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size5};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight5};
  padding-bottom: ${layoutSize3};
`;
export const CardLabel = styled.p`
  color: ${gray9};
  font-family: ${family0};
  font-size: ${size1};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
`;
export const CardLabelText = styled.p`
  color: ${gray9};
  font-family: ${family0};
  font-size: ${size1};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
`;

export const CardListInfo = styled.p`
  display: flex;
  gap: ${layoutSize2};
  align-items: center;
`;

export const CardContainerList = styled.p`
  display: inline-grid;
  gap: ${layoutSize4};
`;
export const Line = styled.div`
  border-top: 1px solid ${gray3};
  margin-bottom: ${layoutSize6};
`;
