import styled from 'styled-components';
import { tokens } from '@veneer/tokens';

const { size2 } = tokens.typography;
export const ModalButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${size2};
  width: 100%;
`;
