import React from 'react';
import {
  Card,
  Title,
  Container,
  CardText,
  CardContent,
  CardIcon
} from './Styles';
import { IconInfo } from '@veneer/core';

const NonCissPrinter = ({ t, isInRemorse, props }) => {
  return (
    <Card>
      <CardIcon>
        <IconInfo
          size={24}
          filled
          color="hpBlue7"
        />
      </CardIcon>
      <CardContent>
        <>
          <Title>
            {t(
              'ReturnDevice.notification.nonCissPrinter.title',
              'How to return the printer and ink cartridges'
            )}
          </Title>
        </>

        {isInRemorse ? (
          <Container>
            <CardText>
              {t(
                'ReturnDevice.notification.nonCissPrinter.remorse-title1',
                'Look for a FedEx email in your inbox.  It may take up to a day to arrive. It will include pre-paid shipping instructions and QR code.'
              )}
            </CardText>
            <CardText>
              {t(
                'ReturnDevice.notification.nonCissPrinter.remorse-title2',
                'If you don’t see the email in your inbox after 24 hours, please check your spam folder.'
              )}
            </CardText>
          </Container>
        ) : (
          <Container>
            <CardText>
              {t(
                'ReturnDevice.notification.nonCissPrinter.afterremorse-title1',
                {
                  cancellationInvoicedDate: props?.cancellationInvoicedDate,
                  defaultValue:
                    'You will receive a FedEx email after {{cancellationInvoicedDate}}. It will include pre-paid shipping instructions and QR code.'
                }
              )}
            </CardText>
            <CardText>
              {t(
                'ReturnDevice.notification.nonCissPrinter.afterremorse-title2',
                'If you don’t see the email in your inbox, please check your spam folder.'
              )}
            </CardText>
          </Container>
        )}
      </CardContent>
    </Card>
  );
};

export { NonCissPrinter };
