export const revertSubscription = async (subscriptionData) => {
  if (!subscriptionData) {
    return [];
  }
  const rescindCancelData =
    subscriptionData?.subscription?.cancellation?.filter(
      (item) => item.name === 'rescindCancel' && item.enabled === true
    );

  return rescindCancelData;
};
