import { useState } from 'react';
import { SubscriptionsClient } from 'src/api/SubscriptionClient';
import { revertSubscription } from 'src/utils/revertSubscription';

const useSubscriptionOptions = (authProvider, stack, bizlogicId) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isRescindCancelEnabled, setIsRescindCancelEnabled] =
    useState<boolean>(false);
  const [optionId, setOptionId] = useState<string>('');

  const subscriptionClient = new SubscriptionsClient(
    authProvider,
    stack,
    bizlogicId
  );

  const fetchSubscriptionOptions = async (subscriptionId, entityId) => {
    setLoading(true);
    try {
      const options = await subscriptionClient.getSubscriptionOptions(
        subscriptionId,
        entityId
      );
      const rescindCancelData = await revertSubscription(options);
      if (rescindCancelData?.length > 0) {
        setIsRescindCancelEnabled(true);
        setOptionId(rescindCancelData?.[0]?.optionId);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  return {
    loading,
    isRescindCancelEnabled,
    optionId,
    fetchSubscriptionOptions
  };
};

export default useSubscriptionOptions;
