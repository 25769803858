import { useState, useEffect } from 'react';
import { AddressStateClient } from '../services/AddressStateClient';
const useAddress = (shippingAddressId, authToken, stack) => {
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAddress = async () => {
      if (!shippingAddressId) return;

      setLoading(true);

      try {
        const client = new AddressStateClient(authToken, stack);
        const fetchedAddress = await client.getAddressById(shippingAddressId);
        setAddress(fetchedAddress);
      } catch (err) {
        setAddress(null);
      } finally {
        setLoading(false);
      }
    };

    fetchAddress();
  }, [shippingAddressId, authToken, stack]);

  return { address, loading };
};

export default useAddress;
