import styled from 'styled-components';
import { tokens } from '@veneer/tokens';
const { size1: layoutSize1 } = tokens.layout;
const { gray12, hpBlue7 } = tokens.color;
const {
  size3,
  size2,

  lineHeight3,
  lineHeight2,

  family0
} = tokens.typography;
export const SupportCardLabel = styled.p`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size3};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
  margin-bottom: ${layoutSize1};
`;
export const SupportCardText = styled.p`
  color: ${hpBlue7};
  font-family: ${family0};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
`;
