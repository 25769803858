import React from 'react';
import {
  Card,
  Title,
  Container,
  CardText,
  CardContent,
  CardIcon,
  Link
} from './Styles';
import { IconInfo } from '@veneer/core';
import { redirectURLs } from 'src/components/configs/external-link';
const CissPrinter = ({ t, isInRemorse, props }) => {
  return (
    <Card>
      <CardIcon>
        <IconInfo
          size={24}
          filled
          color="hpBlue7"
        />
      </CardIcon>
      <CardContent>
        <>
          <Title>
            {t(
              'ReturnDevice.notification.cissprinter.title',
              'How to return the printer'
            )}
          </Title>
        </>

        {isInRemorse ? (
          <Container>
            <CardText>
              {t(
                'ReturnDevice.notification.cissprinter.remorse-title1',
                'Look for a FedEx email in your inbox. It may take up to a day to arrive. It will include pre-paid shipping instructions and QR code.'
              )}
            </CardText>
            <CardText>
              {t(
                'ReturnDevice.notification.cissprinter.remorse-title2',
                'If you don’t see the email in your inbox after 24 hours, please check your spam folder.'
              )}
            </CardText>
            <CardText>
              {t(
                'ReturnDevice.notification.cissprinter.remorse-title3',
                'To prevent ink leaks or printer damage, please visit'
              )}{' '}
              <Link
                href={redirectURLs.transportSmartTank}
                target="_blank"
              >
                hp.com/support/transportsmarttank
              </Link>
            </CardText>
          </Container>
        ) : (
          <Container>
            <CardText>
              {t('ReturnDevice.notification.cissprinter.afterremorse-title1', {
                cancellationInvoicedDate: props?.cancellationInvoicedDate,
                defaultValue:
                  'You will receive a FedEx email after {{cancellationInvoicedDate}}. It will include pre-paid shipping instructions and QR code.'
              })}
            </CardText>
            <CardText>
              {t(
                'ReturnDevice.notification.cissprinter.afterremorse-title2',
                'If you don’t see the email in your inbox, please check your spam folder.'
              )}
            </CardText>
            <CardText>
              {t(
                'ReturnDevice.notification.cissprinter.remorse-title3',
                'To prevent ink leaks or printer damage, please visit'
              )}{' '}
              <Link
                href={redirectURLs.transportSmartTank}
                target="_blank"
              >
                hp.com/support/transportsmarttank
              </Link>
            </CardText>
          </Container>
        )}
      </CardContent>
    </Card>
  );
};

export { CissPrinter };
