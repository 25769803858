import { useState, useMemo } from 'react';
import { CommerceBillingCycleClient } from '../api/commerceBillingCycle';
import moment from 'moment';

type BillingCycleHookReturnType = {
  billingDate: any;
  invoiceDate: any;
  loading: boolean;
  called: boolean;
  error: Error | null;
};

export const useBillingCycle = (
  authProvider,
  stack,
  data
): [() => Promise<void>, BillingCycleHookReturnType] => {
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>(null);
  //   const [data, setData] = useState<any>(null);
  const [billingDate, setBillingDate] = useState<any>();
  const [invoiceDate, setInvoiceDate] = useState<any>();

  const billingDueDate = useMemo(() => {
    return new CommerceBillingCycleClient(authProvider, stack);
  }, [authProvider, stack]);
  const getBillingCycles = async () => {
    setLoading(true);
    let invoiceDate;
    try {
      const billingDueDateDetails = await billingDueDate.getBillingDetails(
        data?.printerSubscription?.commerce?.value?.subscriptionId
      );
      // Check if billingDueDateDetails exists and contains customerEndDate
      if (billingDueDateDetails?.customerEndDate) {
        const customerEndDate = formatDate(
          billingDueDateDetails.customerEndDate
        );
        setBillingDate(customerEndDate);
        invoiceDate = moment(customerEndDate)
          .add(1, 'day')
          .format('MMM DD, YYYY');
      }
      // If customerEndDate doesn't exist, check for billingDate

      // If neither customerEndDate nor billingDate exist, fallback to cancelledOn
      else if (billingDueDateDetails?.cancelledOn) {
        const cancelledDate = formatDate(billingDueDateDetails.cancelledOn);
        const billing = moment(cancelledDate)
          .subtract(1, 'day')
          .format('MMM DD, YYYY');
        setBillingDate(billing);
        invoiceDate = moment(cancelledDate).format('MMM DD, YYYY');
      }
      // Invoice date one day after cancel
      setInvoiceDate(invoiceDate);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
      setCalled(true);
    }
  };

  function formatDate(date) {
    return moment(date.split('T')[0]).format('MMM DD, YYYY');
  }

  return [
    getBillingCycles,
    { billingDate, invoiceDate, loading, called, error }
  ];
};
