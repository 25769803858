import React from 'react';
import { CardWrapper, Title, Line, BodySection, BodyText } from './styles';
import { IconReload } from '@veneer/core';
import ReactiveSubscriptionModal from '../ReactiveSubscriptionModal/ReactiveSubscriptionModal';

const ResumeSubscription = ({
  t,
  navigation,
  authProvider,
  stack,
  optionId,
  bizlogicId,
  props
}) => {
  return (
    <CardWrapper>
      <Title>
        {t(
          'SecondComponent.ReactiveSubscription.header',
          'Want to resume your subscription?'
        )}
      </Title>
      <Line />
      <BodySection>
        <IconReload size={40} />
        <BodyText>
          {t(
            'SecondComponent.ReactiveSubscription.title',
            'Resume HP All-In Plan subscription?'
          )}
          <ReactiveSubscriptionModal
            t={t}
            link={t('SecondComponent.CardLink', 'Keep subscription as it was')}
            navigation={navigation}
            props={props}
            authProvider={authProvider}
            stack={stack}
            optionId={optionId}
            bizlogicId={bizlogicId}
          />
        </BodyText>
      </BodySection>
    </CardWrapper>
  );
};

export default ResumeSubscription;
