import environments from '../configs/env.json';

const setEnvironmentVariables = (key) => {
  const env = environments[key];

  if (env) {
    return env;
  }
  return undefined;
};

export default setEnvironmentVariables;
