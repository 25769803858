import React from 'react';
import { CenterContainerPaper } from './styles';
import { ProgressIndicator } from '@veneer/core';
function LoadingPage() {
  return (
    <CenterContainerPaper
      className={`loading-container `}
      $position={'absolute'}
    >
      <ProgressIndicator
        data-testid="loading-indicator"
        appearance="circular"
        behavior="indeterminate"
        color="hpBlue6"
      />
    </CenterContainerPaper>
  );
}

export default LoadingPage;
