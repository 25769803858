import React from 'react';
import { CardLabel, CardText } from '../Style';
import { IconPrinterMinus } from '@veneer/core';
import { internalURLs } from 'src/components/configs/internal-link';
import { captureClickEvents } from '../../../../services/DataCollection/clickActions';
import { AnalyticsClickEventsList } from '../../../../constants';
import useModifiedUrl from 'src/hooks/useModifiedUrl';

const PrintPlanChange = ({
  startDate,
  subscriptionId,
  navigation,
  t,
  trialPeriod
}) => {
  const finalPath = useModifiedUrl(internalURLs.UpdatePlanURL);
  return (
    <>
      <IconPrinterMinus size={40} />
      <div>
        <CardLabel>
          {t('PrintPlanChange.title', 'Have your printing needs changed?')}
        </CardLabel>
        <a
          href="#"
          target="_self"
          rel="noreferrer"
          onClick={() => {
            navigation.push(finalPath);
            captureClickEvents(
              AnalyticsClickEventsList.CHANGE_PLAN,
              startDate,
              subscriptionId,
              trialPeriod
            );
          }}
        >
          <CardText>
            {t('PrintPlanChange.subtitle', 'Change to a different plan')}
          </CardText>
        </a>
      </div>
    </>
  );
};

export default PrintPlanChange;
