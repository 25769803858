import React from 'react';
import { CardLabel, CardText } from '../PrintPlanChange/Style';
import { IconPrinterUpgrade } from '@veneer/core';
import { internalURLs } from 'src/components/configs/internal-link';

import useModifiedUrl from 'src/hooks/useModifiedUrl';

const PrintPlanChange = ({
  navigation,
  t,

  componentType
}) => {
  const finalPath = useModifiedUrl(internalURLs.UpdatePlanURL);
  return (
    <>
      <IconPrinterUpgrade size={40} />

      <div>
        {componentType === 'PaperFirstComponent' ? (
          <CardLabel>
            {t(
              'Paper.FirstComponent.PrintPlanChange.title',
              'Update your HP All-In Plan?'
            )}
          </CardLabel>
        ) : (
          <CardLabel>
            {t('Paper.SecondComponent.UpdatePlan', 'Update your plan?')}
          </CardLabel>
        )}
        <a
          href="#"
          target="_self"
          rel="noreferrer"
          onClick={() => {
            navigation.push(finalPath);
          }}
        >
          <CardText>
            {t(
              'Paper.FirstComponent.PrintPlanChange.subtitle',
              'Change your plan'
            )}
          </CardText>
        </a>
      </div>
    </>
  );
};

export default PrintPlanChange;
