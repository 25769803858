import styled from 'styled-components';
import { tokens } from '@veneer/tokens';
const {
  size8: layoutSize8,
  cornerRadius4,
  size1: layoutSize1,
  size4: layoutSize4,
  xsMax
} = tokens.layout;
const { gray12, gray9, gray6 } = tokens.color;
const { size3, size2, size9, size7, lineHeight3, lineHeight2, family0 } =
  tokens.typography;

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: ${size7};
  gap: ${layoutSize8};
  border: 1px solid ${gray6};
  border-radius: ${cornerRadius4};
  background: #fff;
`;
export const FeedbackContainer = styled.div`
  display: flex;
  gap: ${layoutSize4};
  margin-top: ${layoutSize8};
  margin-bottom: 40px;
`;
export const InnerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${size9};
  width: 100%;
  @media (max-width: ${xsMax}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const CartTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

export const CardTitle = styled.div`
  font-family: ${family0};
  font-size: ${size3};
  font-weight: 700;
  line-height: ${lineHeight3};
  color: ${gray12};
  margin-bottom: ${layoutSize1};
`;

export const CardSubTitle = styled.p`
  display: flex;
  font-family: ${family0};
  font-size: ${size2};
  font-weight: 400;
  line-height: ${lineHeight2};
  color: ${gray9};
`;

export const ButtonContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  @media (max-width: ${xsMax}) {
    width: 100%;
    > button {
      width: 100%;
    }
  }
`;

export const TooltipContainer = styled.span`
  margin-left: ${layoutSize1};
`;
