import React from 'react';
import { StepThreeCardAlignment } from './Styles';

import PrinterDetails from '../CommonComponent/PrinterDetails';
import ReturnDevice from '../CommonComponent/ReturnDevice/ReturnDevice';
export const DeviceComponent = ({ t, props, isInRemorse }) => {
  return (
    <>
      {props && (
        <PrinterDetails
          props={props}
          t={t}
        />
      )}
      <StepThreeCardAlignment />
      <ReturnDevice
        t={t}
        props={props}
        isInRemorse={isInRemorse}
      />
    </>
  );
};
