export function checkCancellationPeriod(optionsData) {
  const optionCancellationData = optionsData.subscription.cancellation;
  // Get the current date
  const currentDate = new Date();
  //const currentDate = new Date('2023-07-20T00:00:00.000Z');
  const filteredData = optionCancellationData.filter((item) => {
    const validFromDate = new Date(item.details.validFrom);
    const validUntilDate = new Date(item.details.validUntil);

    return (
      item.enabled === true &&
      item.details.returnDevice === true &&
      currentDate > validFromDate &&
      currentDate < validUntilDate
    );
  });
  return filteredData.length === 0
    ? { error: true, period: [] }
    : { error: false, period: filteredData };
}
