import { Action, SimpleUiEvent } from '../../types/webanalytics';

const simpleUiEventVersion = '1.4.0';

export const uiEvents = {
  cancelDisplayScreen: {
    version: simpleUiEventVersion,
    action: Action.screenDisplayed,
    activity: 'HpOneCancellation-v01',
    screenName: 'CancelHpAllInPlan',
    screenPath: '/CancellationReact/'
  } as SimpleUiEvent,
  keepPlanLink: {
    version: simpleUiEventVersion,
    action: Action.controlButtonClicked,
    activity: 'HpOneCancellation-v01',
    screenName: 'CancelHpAllInPlan',
    screenMode: 'Commitment',
    screenPath: '/CancellationReact/',
    controlName: 'KeepPlan',
    actionAuxParams: ''
  } as SimpleUiEvent,
  confirmCancellation: {
    version: simpleUiEventVersion,
    action: Action.controlButtonClicked,
    activity: 'HpOneCancellation-v01',
    screenName: 'CancelHpAllInPlan',
    screenMode: 'Commitment',
    screenPath: '/CancellationReact/',
    controlName: 'ConfirmCancellation',
    actionAuxParams: ''
  } as SimpleUiEvent,
  supportLink: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOneCancellation-v01',
    screenName: 'CancelHpAllInPlan',
    screenMode: 'PostCommitment',
    screenPath: '/CancellationReact/',
    controlName: 'Support',
    actionAuxParams: ''
  } as SimpleUiEvent,
  supportLinkSecond: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOneCancellation-v01',
    screenName: 'CancelHpAllInPlanSuccess',
    screenMode: 'PostCommitment',
    screenPath: '/CancellationReact/',
    controlName: 'Support',
    actionAuxParams: ''
  } as SimpleUiEvent,
  ChangePlanLink: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOneCancellation-v01',
    screenName: 'CancelHpAllInPlan',
    screenMode: 'Commitment',
    screenPath: '/CancellationReact/',
    controlName: 'ChangePlan',
    actionAuxParams: ''
  } as SimpleUiEvent,
  CancelHpAllInPlanSuccessWindowDisplay: {
    version: simpleUiEventVersion,
    action: Action.screenDisplayed,
    activity: 'HpOneCancellation-v01',
    screenMode: 'Commitment',
    screenName: 'CancelHpAllInPlanSuccess',
    screenPath: '/CancellationReact/',
    actionAuxParams: ''
  } as SimpleUiEvent,
  cancellationFee: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOneCancellation-v01',
    screenName: 'CancelHpAllInPlanSuccess',
    screenMode: 'Commitment',
    screenPath: '/CancellationReact/',
    controlName: 'CancellationFee',
    actionAuxParams: ''
  } as SimpleUiEvent,
  recycling: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOneCancellation-v01',
    screenMode: 'Commitment',
    screenName: 'CancelHpAllInPlanSuccess',
    screenPath: '/CancellationReact/',
    controlName: 'Recycling',
    actionAuxParams: ''
  } as SimpleUiEvent,
  cancellationFeeModal: {
    version: simpleUiEventVersion,
    action: Action.modalWindowDisplayed,
    activity: 'HpOneCancellation-v01',
    screenName: 'CancellationFeeModal',
    screenPath: '/CancellationReact/',
    actionAuxParams: ''
  } as SimpleUiEvent,
  recyclingModal: {
    version: simpleUiEventVersion,
    action: Action.modalWindowDisplayed,
    activity: 'HpOneCancellation-v01',
    screenName: 'RecyclingModal',
    screenPath: '/CancellationReact/',
    actionAuxParams: ''
  } as SimpleUiEvent,
  returnToDashboard: {
    version: simpleUiEventVersion,
    action: Action.controlButtonClicked,
    activity: 'HpOneCancellation-v01',
    screenName: 'CancelHpAllInPlanSuccess',
    screenPath: '/CancellationReact/',
    controlName: 'ReturnToDashboard',
    actionAuxParams: ''
  } as SimpleUiEvent,
  continue: {
    version: simpleUiEventVersion,
    action: Action.controlButtonClicked,
    activity: 'HpOneCancellation-v01',
    screenName: 'CancelHpAllInPlanSuccess',
    screenPath: '/CancellationReact/',
    controlName: 'Continue',
    actionAuxParams: ''
  } as SimpleUiEvent,
  cancelPlanSuccessSupport: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOneCancellation-v01',
    screenName: 'CancelHpAllInPlanSuccess',
    screenPath: '/CancellationReact/',
    controlName: 'Support',
    actionAuxParams: ''
  } as SimpleUiEvent,
  CancelHpAllInPlanSurvey: {
    version: simpleUiEventVersion,
    action: Action.screenDisplayed,
    activity: 'HpOneCancellation-v01',
    screenName: 'CancelHpAllInPlanSurvey',
    screenPath: '/CancellationReact/'
  } as SimpleUiEvent
};
