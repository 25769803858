import styled from 'styled-components';
import tokens from '@veneer/tokens';

const { color, layout, typography } = tokens;

export const RemorseTitle = styled.p`
  color: ${color.gray12};
  font-size: ${typography.size5};
  line-height: ${typography.lineHeight5};
`;

export const RemorseSubTitle = styled.p`
  color: ${color.gray12};
  font-size: ${typography.size1};
  line-height: ${typography.lineHeight2};
  margin-top: ${layout.size2};
`;

export const Line = styled.div`
  border: 1px solid ${color.gray3};
  margin: ${layout.size2} 0;
`;

export const TaxText = styled.p`
  color: ${color.gray6};
  font-size: ${typography.size0};
  line-height: ${typography.lineHeight1};
  margin: 40px 0;
`;
