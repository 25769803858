import styled from 'styled-components';
import tokens from '@veneer/tokens';

const { color, layout, typography } = tokens;

export const CardWrapper = styled.div`
  margin-top: 40px;
  padding: ${layout.size8};
  border-radius: ${layout.cornerRadius4};
  border: 1px solid ${color.gray6};
  background: ${color.white};
`;

export const Title = styled.p`
  color: ${color.gray12};
  font-size: ${typography.size5};
  line-height: ${typography.lineHeight5};
  margin-bottom: ${layout.size3};
`;

export const Line = styled.div`
  border: 1px solid ${color.gray3};
  margin: ${layout.size2} 0 ${layout.size6} 0;
`;

export const BodySection = styled.div`
  display: flex;
  gap: 16px;
`;

export const BodyText = styled.p`
  color: ${color.gray12};
  font-size: ${typography.size3};
  line-height: ${typography.lineHeight3};
`;
