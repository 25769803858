import tokens from '@veneer/tokens';
import styled from 'styled-components';

const { color, typography } = tokens;

export const CancelDeviceInfoWrapper = styled.div`
  margin-bottom: 40px;
  box-sizing: border-box;
  @media (min-width: 1100px) {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }
`;

export const LeftBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 1100px) {
    width: 70%;
  }
`;

export const Title = styled.p`
  font-size: ${typography.size7};
  line-height: ${typography.lineHeight7};
  color: ${color.gray12};
  margin-bottom: 40px;
`;

export const CancellationText = styled.p<{ margin?: string }>`
  color: ${color.gray12};
  font-size: ${typography.size1};
  line-height: ${typography.lineHeight2};
  margin: ${(props) => props.margin || '40px 0'};
`;

export const BoldText = styled.span`
  color: ${color.gray12};
  font-size: ${typography.size1};
  font-weight: 700;
  line-height: ${typography.lineHeight2};
`;

export const TaxText = styled.p`
  color: ${color.gray6};
  font-size: ${typography.size0};
  line-height: ${typography.lineHeight1};
  margin-bottom: 40px;
`;

export const LinkText = styled.a`
  color: ${color.hpBlue7};
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  margin-top: auto;
  display: flex;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 1100px) {
    margin-bottom: 40px;
  }

  > button {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
