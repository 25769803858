import React from 'react';
import {
  PaperLoadingCard,
  DeviceCardLoader,
  PaperDeviceCard,
  CardTitle,
  PaperImage,
  ProgeressButtonGroup
} from './Style';
import PrinterDetails from '../PrinterDetails';
import Images from 'src/assets/images';
import { Button } from '@veneer/core';
const PaperLoadingComponent = ({ t, props, isLoading }) => {
  return (
    <PaperLoadingCard>
      <DeviceCardLoader>
        <PaperDeviceCard>
          <CardTitle>
            {t(
              'Paper.FirstComponent.Loading-title',
              'Just a second, your Paper Add-on Service removal request is being processed'
            )}
          </CardTitle>
          <div>
            <PrinterDetails
              t={t}
              props={props}
            />
          </div>
          <ProgeressButtonGroup>
            <Button
              data-testid="Confirm Paper Add-on Service removal"
              loading={isLoading}
            >
              {t(
                'Paper.FirstComponent.button.Confirm-Cancellation',
                'Confirm Paper Add-on Service removal'
              )}
            </Button>
          </ProgeressButtonGroup>
        </PaperDeviceCard>

        <PaperImage
          src={Images.PaperProgressImage}
          alt="Paper Progress Image"
        ></PaperImage>
      </DeviceCardLoader>
    </PaperLoadingCard>
  );
};

export { PaperLoadingComponent };
