import React, { useEffect } from 'react';
import { SurveyContainer } from './styles';
import { checkCondition } from 'src/utils/surveyFunctions';
import setEnvironmentVariables from 'src/helpers/setEnvironmentVariables';
import { TranslatorFunctionType } from 'src/types/localization';

interface EmbeddedSurveyProps {
  subscriptionId: string;
  isInRemorse: boolean;
  deviceName: string;
  planDetails: string;
  stack: number;
  pagesPerMonth: number;
  t: TranslatorFunctionType;
  countryCode: string;
  languageCode: string;
}

const EmbeddedSurvey = (props: EmbeddedSurveyProps) => {
  const {
    subscriptionId,
    isInRemorse,
    deviceName,
    planDetails,
    stack,
    pagesPerMonth,
    t,
    countryCode,
    languageCode
  } = props;

  const envirnoment = setEnvironmentVariables(stack);
  const cancellationSurvey = envirnoment?.CANCELLATION_SURVEY;

  function loadScript(src: string): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    if (document.body) {
      document.body.appendChild(script);
    }
  }

  useEffect(() => {
    const e = 100;
    const h = 'r';
    const f = cancellationSurvey?.COOKIE_ID;
    const g = cancellationSurvey?.SCRIPT_URL;

    try {
      if (checkCondition(e, h, f)) {
        loadScript(g);
      }
    } catch (error) {
      console.error('loadScript error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SurveyContainer role="presentation">
        <p id="subscriptionID">{subscriptionId}</p>
        <p id="cancellationType">
          {isInRemorse ? 'InTrialPeriod' : 'AfterTrialPeriod'}
        </p>
        <p id="deviceName">{deviceName}</p>
        <p id="planDetails">{planDetails}</p>
        <p id="pagesPerMonth">{`${pagesPerMonth} ${t(
          'PrinterDetails.pages',
          'pages / month'
        )}`}</p>
        {/* DO NOT DELETE CONDITIONAL_ID */}
        <p id={cancellationSurvey?.CONDITIONAL_ID}>
          {cancellationSurvey?.CONDITIONAL_ID}
        </p>
        <p id="countryCode">{countryCode}</p>
        <p id="languageCode">{languageCode}</p>
      </SurveyContainer>
      <div id={cancellationSurvey?.DIV_ID} />
    </>
  );
};

export default EmbeddedSurvey;
