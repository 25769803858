import React, { useEffect, useMemo, useState } from 'react';
import {
  ReactivePaperButtons,
  ReactivePaperSubscriptionCardText,
  ModalContainer
} from './Style';
import { Button, Modal } from '@veneer/core';
import { internalURLs } from 'src/components/configs/internal-link';
import { SubscriptionsClient } from 'src/api/SubscriptionClient';
import { getPayloadWithOptionId } from 'src/types/subscription';
import { TranslatorFunctionType } from 'src/types/localization';
import { JarvisAuthProvider } from '@jarvis/web-http';
import { Stack } from 'src/types/stratus';
import { CancelState } from 'src/types/initialCancelState';
import useModifiedUrl from 'src/hooks/useModifiedUrl';
type NavigationType = {
  push: (url: string) => void;
};

interface ReactivePaperSubscriptionModalProps {
  t: TranslatorFunctionType;
  link: string;
  navigation?: NavigationType;
  props?: CancelState;
  authProvider?: JarvisAuthProvider;
  stack?: Stack;
  optionId?: string;
  bizlogicId?: string;
  showModel?: boolean;
}

const PaperReactivationModal: React.FC<ReactivePaperSubscriptionModalProps> = ({
  t,
  link,
  navigation,
  props,
  authProvider,
  stack,
  optionId,
  bizlogicId,
  showModel
}) => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isTryOpenModal, setIsTryOpenModal] = useState(false);
  const [isReactive, setIsReactive] = useState(false);
  const [redirectTo, setRedirectTo] = useState<string | null>(null);

  const subscriptionClient = useMemo(
    () => new SubscriptionsClient(authProvider, stack, bizlogicId),
    [authProvider, stack, bizlogicId]
  );

  const updatePaperSubscription = async () => {
    try {
      await subscriptionClient.updateSubscription(
        props?.instantPaperEntityDetails?.subscriptionId,
        getPayloadWithOptionId(optionId)
      );
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };
  const modifiedOverviewUrl = useModifiedUrl(internalURLs.PrinterOverviewURL);
  const modifiedUpdatePlanUrl = useModifiedUrl(internalURLs.UpdatePlanURL);
  const modifiedSubscriptionUrl = useModifiedUrl(internalURLs.SubscriptionURL);
  const finalPath = useMemo(() => {
    switch (redirectTo) {
      case 'Overview':
        return modifiedOverviewUrl;
      case 'updatePlan':
        return modifiedUpdatePlanUrl;
      case 'subscription':
        return modifiedSubscriptionUrl;
      default:
        return modifiedOverviewUrl;
    }
  }, [
    redirectTo,
    modifiedOverviewUrl,
    modifiedSubscriptionUrl,
    modifiedUpdatePlanUrl
  ]);

  const reactivePaperSubscription = async () => {
    setIsReactive(true);
    const success = await updatePaperSubscription();
    if (success) {
      navigation?.push(`${finalPath}?rescindPaperCanel=success`);
      setIsModelOpen(false);
      setIsTryOpenModal(false);
    } else {
      setIsTryOpenModal(true);
      setIsReactive(false);
      setIsModelOpen(false);
    }
  };

  useEffect(() => {
    if (isModelOpen || isTryOpenModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isModelOpen, isTryOpenModal]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const rescindCancel = params.get('rescind');
    const redirectToParam = params.get('redirectTo');
    setRedirectTo(redirectToParam);
    if (rescindCancel === 'true' && showModel) {
      setIsModelOpen(true);
    }
  }, []);

  const handleNoThanks = () => {
    const params = new URLSearchParams(window.location.search);
    const rescindCancel = params.get('rescind');
    if (rescindCancel === 'true') {
      navigation?.push(finalPath);
    } else {
      setIsModelOpen(false);
    }
  };

  return (
    <>
      <a
        href="#"
        data-testid="modal-close-button"
        onClick={() => setIsModelOpen(true)}
      >
        <ReactivePaperSubscriptionCardText>
          {link}
        </ReactivePaperSubscriptionCardText>
      </a>

      <ModalContainer
        align="start"
        closeButton={false}
        show={isModelOpen}
        onClose={() => setIsModelOpen(false)}
        data-testid="modal-content1"
        footer={
          <ReactivePaperButtons>
            <Button
              data-testid="No, thanks"
              appearance="secondary"
              onClick={handleNoThanks}
            >
              {t('Paper.PaperReactivationModel.button.noThanks', 'No, thanks')}
            </Button>
            <Button
              data-testid="resume-button"
              appearance="secondary"
              onClick={reactivePaperSubscription}
              loading={isReactive}
            >
              {t(
                'Paper.PaperReactivationModel.button.resume',
                'Resume service'
              )}
            </Button>
          </ReactivePaperButtons>
        }
        title={t(
          'Paper.PaperReactivationModel.title',
          'Please confirm that you want to resume your Paper Add-on Service'
        )}
      >
        {''}
      </ModalContainer>

      <Modal
        align="start"
        closeButton={true}
        show={isTryOpenModal}
        onClose={() => setIsTryOpenModal(false)}
        data-testid="modal-content2"
        footer={
          <ReactivePaperButtons>
            <Button
              appearance="secondary"
              onClick={reactivePaperSubscription}
            >
              {t(
                'Paper.PaperReactivationModel.button.tryItAgain',
                'Try it again'
              )}
            </Button>
          </ReactivePaperButtons>
        }
        title={t(
          'Paper.PaperReactivationModel.error-title',
          'Something went wrong'
        )}
      >
        <p>
          {t(
            'Paper.PaperReactivationModel.error-content',
            'It has not been possible to resume the  Paper Add-on Service.'
          )}
        </p>
      </Modal>
    </>
  );
};

export default PaperReactivationModal;
