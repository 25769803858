export function checkPaperCancellationPeriod(
  optionsData,
  instantPaperEntityDetails
) {
  const optionCancellationData = optionsData?.entities?.filter(
    (item) => item?.entityId === instantPaperEntityDetails?.entityId
  );

  const filteredCancellations = optionCancellationData[0]?.cancellation?.filter(
    (cancellation) => {
      return (
        cancellation?.details?.productType === 'instantPaper' &&
        cancellation?.details?.action === 'cancel' &&
        cancellation?.enabled === true
      );
    }
  );
  return filteredCancellations?.length === 0
    ? { error: true, period: [] }
    : { error: false, period: filteredCancellations };
}
