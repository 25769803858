import React from 'react';
import { Container, CardTitle, CardText, Line, InsideCard } from './styles';
import { Button } from '@veneer/core';
import { internalURLs } from '../../configs/internal-link';
import { AnalyticsClickEventsList } from '../../../constants';
import CustomerSupport from '../CommonComponent/customerSupportComponent';
import useModifiedUrl from 'src/hooks/useModifiedUrl';
const ErrorPage = ({ t, navigation, props }) => {
  const finalPath = useModifiedUrl(internalURLs.PrinterOverviewURL);
  return (
    <>
      <Container>
        <CardTitle>
          {t('ErrorComponent.title', 'Something went wrong')}
        </CardTitle>
        <CardText>
          {t(
            'ErrorComponent.subtitle',
            'Your subscription information did not load.'
          )}
        </CardText>
        <Button
          onClick={() => {
            navigation.push(finalPath);
          }}
          appearance="secondary"
        >
          {t('ErrorComponent.button', 'Return')}
        </Button>
        <Line />
        <InsideCard>
          <CustomerSupport
            props={props}
            t={t}
            captureEvent={AnalyticsClickEventsList.SUPPORT_FIRST}
          />
        </InsideCard>
      </Container>
    </>
  );
};

export default ErrorPage;
