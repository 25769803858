// Define the types and export them
export interface Value {
  cost: number;
  currency: string;
}

export interface EntityValue {
  entityId: string;
  receivedBy: string;
}

export interface Condition {
  type: string;
  value: EntityValue;
}

export type ConditionalValue = {
  condition: Condition;
};

export interface Outcome {
  type: string;
  value: Value | ConditionalValue;
}
export interface CancellationOutcome {
  type: string;
  value: CancellationOutcomeValue | ConditionalCancellationOutcomeValue;
}

export interface CancellationOutcomeValue {
  cost: number;
  currency: string;
}

export interface ConditionalCancellationOutcomeValue {
  condition?: {
    type: string;
    value: {
      entityId: string;
      receivedBy: string;
    };
  };
  cost?: number;
  currency?: string;
}

export interface Cancellation {
  name: string;
  optionId: string;
  enabled: boolean;
  locked: boolean;
  apply: string;
  type: string;
  details: {
    validFrom: string;
    validUntil: string;
    returnDevice?: boolean;
  };
  outcomes: (CancellationOutcome | ConditionalOutcome)[];
}

export type ConditionalOutcome = {
  condition: Condition;
  outcomes: Outcome[];
};

export interface EntityEdit {
  enabled: boolean;
  locked: boolean;
  optionId: string;
  apply: string;
  recommended: boolean;
  product: {
    type: string;
    value: {
      productName: string;
      parentProductSku: string;
      productSku: string;
      productType: string;
    };
  };
  outcomes: Outcome[];
}

export interface Entity {
  entityId: string;
  edit: EntityEdit[];
}

export interface Subscription {
  cancellation: Cancellation[];
  edit: {
    enabled: boolean;
    locked: boolean;
    optionId: string;
  };
}

export interface SubscriptionOptionsResponse {
  subscription: Subscription;
  entities: Entity[];
}

export interface SubscriptionEntityUpdate {
  optionId: string;
}

export const getPayloadWithOptionId = (
  optionId: string
): SubscriptionEntityUpdate => {
  return {
    optionId: optionId
  };
};
