import React from 'react';
import { BoldText, CancellationText, TaxText } from '../styles';

const CancellationRemorseContent = ({ t, props, cancelationModal }) => (
  <>
    <CancellationText data-testid="cancellationRemorse">
      {t(
        'FirstComponent.Description.Content1',
        'The printer will stop working immediately when you cancel your subscription.'
      )}{' '}
      <BoldText data-testid="isCissPrinter">
        {props?.isCissPrinter
          ? t(
              'FirstComponent.Description.Content2-cissprinter',
              'You are required to submit the printer to Fedex for return to HP in the next 10 days to avoid a cancellation fee of'
            )
          : t(
              'FirstComponent.Description.Content2',
              'You are required to submit the printer and ink cartridges to Fedex for return to HP in the next 10 days to avoid a cancellation fee of'
            )}{' '}
        {props?.cancellationPeriodCost}*.{' '}
      </BoldText>
      {t('FirstComponent.Description.Content8', 'See more info about')}
      {cancelationModal}
    </CancellationText>
    <TaxText>
      * {t('FirstComponent.Description.Content7', 'Taxes may be applied.')}
    </TaxText>
  </>
);

export default CancellationRemorseContent;
