import React, { useMemo, useState } from 'react';
import {
  Card,
  CardTitle,
  DeviceCard,
  DeviceBlock,
  DeviceBlockAlignment,
  Image,
  DeviceCardContainer,
  ButtonGroup,
  CardContainer,
  Line,
  InsideCardOne,
  InsideCardTwo,
  PaperCardTitle,
  PaperFooterText
} from '../PaperFirstComponent/Styles';
import { Button } from '@veneer/core';
import { internalURLs } from '../../configs/internal-link';
import PaperPrinterDetails from '../CommonComponent/PrinterDetails/index';
import Images from 'src/assets/images';
import PrintPlanChange from '../CommonComponent/PrintPlanChange';
import PaperCustomerSupport from '../CommonComponent/customerSupportComponent';
import { PaperSecondComponent } from '../PaperSecondComponent/PaperSecondComponent';
import useModifiedUrl from 'src/hooks/useModifiedUrl';
import { SubscriptionsClient } from 'src/api/SubscriptionClient';
import { getPayloadWithOptionId } from 'src/types/subscription';
import PaperErrorModal from '../CommonComponent/PaperErrorModal/PaperErrorModal';
import { PaperLoadingComponent } from '../CommonComponent/PaperLoadingComponent/PaperLoadingComponent';
const PaperFirstComponent = ({
  t,
  navigation,
  props,
  openSecondComponent,
  authProvider,
  stack,
  bizlogicId
}) => {
  const finalPath = useModifiedUrl(internalURLs.PrinterOverviewURL);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const subscriptionClient = useMemo(
    () => new SubscriptionsClient(authProvider, stack, bizlogicId),
    [authProvider, stack, bizlogicId]
  );
  const openSecond = async () => {
    try {
      setIsLoading(true);

      await subscriptionClient.updateSubscription(
        props?.printerSubscription?.subscriptionId,
        getPayloadWithOptionId(props?.cancellationPeriod[0].optionId)
      );

      openSecondComponent();
      /* We remove setTimeOut after actual API works. Now to show the loader component added setTimeout */
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      setSuccess(true);
    } catch (e) {
      console.log('Error', e);
      setModalOpen(true);
      setSuccess(false);
      setError(true);
      setIsLoading(false);
    }
  };
  const handleModalClose = () => {
    setModalOpen(false);
    setError(false);
    setSuccess(true);
  };

  return (
    <div>
      {isLoading && (
        <PaperLoadingComponent
          t={t}
          props={props}
          isLoading={isLoading}
        />
      )}

      {error && !isLoading && (
        <PaperErrorModal
          modalOpen={modalOpen}
          openSecond={openSecond}
          isLoading={isLoading}
          t={t}
          onClose={handleModalClose}
        />
      )}
      {success &&
        !isLoading &&
        (props?.openSecond ? (
          <>
            {props?.openSecond && !isLoading && !error && (
              <PaperSecondComponent
                t={t}
                navigation={navigation}
                props={props}
                authProvider={authProvider}
                stack={stack}
                bizlogicId={bizlogicId}
              />
            )}
          </>
        ) : (
          <Card>
            <DeviceCardContainer>
              <DeviceCard>
                <CardTitle>
                  {t(
                    'Paper.FirstComponent.title',
                    'Remove the Paper Add-on Service from my HP All-In Plan'
                  )}
                </CardTitle>
                <DeviceBlock>
                  <PaperPrinterDetails
                    t={t}
                    props={props}
                  />

                  <DeviceBlockAlignment />
                </DeviceBlock>
              </DeviceCard>

              <Image
                src={Images.PaperPlaceHolderImage}
                alt="Paper Logo"
              ></Image>
            </DeviceCardContainer>
            {/* ButtonGroup Component */}

            <ButtonGroup>
              <Button
                loading={isLoading}
                onClick={() => {
                  openSecond();
                }}
              >
                {t(
                  'Paper.FirstComponent.button.Confirm-Cancellation',
                  'Confirm Paper Add-on Service removal'
                )}
              </Button>
              <Button
                data-testid="Keep Paper Add-on Service"
                appearance="secondary"
                onClick={() => {
                  navigation.push(finalPath);
                }}
              >
                {t(
                  'Paper.FirstComponent.button.Keep-Subscription',
                  'Keep Paper Add-on Service'
                )}
              </Button>
            </ButtonGroup>
            <PaperFooterText>
              {t(
                'Paper.FirstComponent.paper-footer',
                'If you remove the Paper Add-on Service, you may not add it again later.'
              )}
            </PaperFooterText>
            <CardContainer>
              <PaperCardTitle>
                {t(
                  'Paper.FirstComponent.footer.title',
                  'Considered all your options?'
                )}
              </PaperCardTitle>
              <Line />
              <InsideCardOne>
                <PrintPlanChange
                  navigation={navigation}
                  t={t}
                  componentType="PaperFirstComponent"
                />
              </InsideCardOne>
              <Line />
              <InsideCardTwo>
                <PaperCustomerSupport t={t} />
              </InsideCardTwo>
            </CardContainer>
          </Card>
        ))}
    </div>
  );
};

export { PaperFirstComponent };
