import { AnalyticsClickEventsList } from '../../constants';
import DataCollection from './index';
import { uiEvents } from './simpleUiEvents';
import { getactionAuxParams } from '../../services/getactionAuxParams';
import { PeriodType, ScreenMode } from 'src/constants';

export const captureClickEvents = async (
  eventType,
  startDate,
  subscriptionId,
  trialPeriod
) => {
  const actionAuxParams = await getactionAuxParams(startDate, subscriptionId);
  let eventResult;
  let screenMode;
  //Check condition accroding to trial Period
  if (trialPeriod?.[0]?.name === PeriodType.TRIAL) {
    screenMode = ScreenMode.GRACE;
  } else {
    screenMode = ScreenMode.COMMITMENT;
  }
  switch (eventType) {
    case AnalyticsClickEventsList.SCREEN_DISPLAY_FIRST: {
      const event = uiEvents.cancelDisplayScreen;
      event.screenMode = screenMode;
      event.actionAuxParams = actionAuxParams;
      eventResult = DataCollection.sendEvent(event);
      break;
    }
    case AnalyticsClickEventsList.KEEP_PLAN: {
      const event = uiEvents.keepPlanLink;
      event.screenMode = screenMode;
      event.actionAuxParams = actionAuxParams;
      eventResult = DataCollection.sendEvent(event);
      break;
    }
    case AnalyticsClickEventsList.CONFIRM_CANCELLATION_LINK: {
      const event = uiEvents.confirmCancellation;
      event.actionAuxParams = actionAuxParams;
      event.screenMode = screenMode;
      eventResult = DataCollection.sendEvent(event);
      break;
    }
    case AnalyticsClickEventsList.CHANGE_PLAN: {
      const event = uiEvents.ChangePlanLink;
      event.actionAuxParams = actionAuxParams;
      event.screenMode = screenMode;
      eventResult = DataCollection.sendEvent(event);
      break;
    }
    case AnalyticsClickEventsList.CANCELLATION_FEE_LINK: {
      const event = uiEvents.cancellationFee;
      event.actionAuxParams = actionAuxParams;
      event.screenMode = screenMode;
      eventResult = DataCollection.sendEvent(event);
      break;
    }
    case AnalyticsClickEventsList.RECYCLING_LINK: {
      const event = uiEvents.recycling;
      event.actionAuxParams = actionAuxParams;
      event.screenMode = screenMode;
      eventResult = DataCollection.sendEvent(event);
      break;
    }
    case AnalyticsClickEventsList.RECYCLING_PAGE: {
      const event = uiEvents.recyclingModal;
      event.actionAuxParams = actionAuxParams;
      event.screenMode = screenMode;
      eventResult = DataCollection.sendEvent(event);
      break;
    }
    case AnalyticsClickEventsList.RETURN_TO_DASHBOARD: {
      const event = uiEvents.returnToDashboard;
      event.actionAuxParams = actionAuxParams;
      event.screenMode = screenMode;
      eventResult = DataCollection.sendEvent(event);
      break;
    }
    case AnalyticsClickEventsList.CONTINUE: {
      const event = uiEvents.continue;
      event.actionAuxParams = actionAuxParams;
      event.screenMode = screenMode;
      eventResult = DataCollection.sendEvent(event);
      break;
    }
    case AnalyticsClickEventsList.SUPPORT_SUCCESS: {
      const event = uiEvents.cancelPlanSuccessSupport;
      event.actionAuxParams = actionAuxParams;
      event.screenMode = screenMode;
      eventResult = DataCollection.sendEvent(event);
      break;
    }
    case AnalyticsClickEventsList.SUPPORT_FIRST: {
      const event = uiEvents.supportLink;
      event.actionAuxParams = actionAuxParams;
      event.screenMode = screenMode;
      eventResult = DataCollection.sendEvent(event);
      break;
    }
    case AnalyticsClickEventsList.SUPPORT_SECOND: {
      const event = uiEvents.supportLinkSecond;
      event.actionAuxParams = actionAuxParams;
      event.screenMode = screenMode;
      eventResult = DataCollection.sendEvent(event);
      break;
    }
    case AnalyticsClickEventsList.CANCELMODAL: {
      const event = uiEvents.cancellationFeeModal;
      event.actionAuxParams = actionAuxParams;
      event.screenMode = screenMode;

      eventResult = DataCollection.sendEvent(event);
      break;
    }
    case AnalyticsClickEventsList.SCREEN_DISPLAY_SECOND: {
      const event = uiEvents.CancelHpAllInPlanSuccessWindowDisplay;
      event.screenMode = screenMode;
      event.actionAuxParams = actionAuxParams;
      eventResult = DataCollection.sendEvent(event);
      break;
    }
    case AnalyticsClickEventsList.FEEDBACK_SURVEY: {
      const event = uiEvents.CancelHpAllInPlanSurvey;
      event.screenMode = screenMode;
      event.actionAuxParams = actionAuxParams;
      eventResult = DataCollection.sendEvent(event);
      break;
    }
  }
  return eventResult;
};
