import { useEffect, useState } from 'react';
import { EntityType } from 'src/constants';
import getPrinterData from '../utils/printerUtility';

const usePrinterData = (subscriptionData) => {
  const [printerData, setPrinterData] = useState(null);
  const [instantInkData, setInstantInkData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (subscriptionData) {
        const allPrinters = subscriptionData?.entities?.filter(
          (entity) => entity.entityType === EntityType.PRINTER
        );
        let printerData;
        if (allPrinters.length === 1) {
          printerData = allPrinters[0];
        } else {
          printerData = await getPrinterData(allPrinters);
        }
        setPrinterData(printerData);
        setIsLoading(false);
        const instantInkData = subscriptionData?.entities?.find(
          (item) => item?.entityType === EntityType.INSTANTINK
        );
        setInstantInkData(instantInkData);
      }
    };

    fetchData();
  }, [subscriptionData]);

  return { printerData, instantInkData, isLoading };
};

export default usePrinterData;
