import { JarvisAuthProvider } from '@jarvis/web-http';
import {
  Stack,
  StratusClient
} from '@jarvis/web-stratus-client/dist/src/clients/stratus-client';
import { AxiosResponse } from 'axios';
import {
  PaginatedResponse,
  SubscriptionStateResponse
} from 'src/types/subscriptionState';
import { URLS } from '../urlConfig';

export class SubscriptionsStateClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(URLS[stack].API_URLS.SUBSCRIPTION_STATE, authToken);
  }

  getSubscriptionById(
    subscriptionId: string
  ): Promise<AxiosResponse<SubscriptionStateResponse>> {
    return this.jarvisWebHttpInstance.get({
      url: `/${subscriptionId}`
    });
  }
}

export class PendingChangesClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(URLS[stack].API_URLS.SUBSCRIPTION_STATE, authToken);
  }

  getPendingChanges(
    subscriptionId: string
  ): Promise<AxiosResponse<PaginatedResponse>> {
    return this.jarvisWebHttpInstance.get({
      url: `/${subscriptionId}/pending-changes`
    });
  }
}
