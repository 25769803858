import React, { useEffect, useState } from 'react';
import {
  Container,
  CartTextContainer,
  CardTitle,
  CardSubTitle,
  ButtonContainer,
  InnerContainer,
  FeedbackContainer
} from './Styles';
import { Button } from '@veneer/core';

import { AnalyticsClickEventsList } from '../../../../constants';
import { captureClickEvents } from '../../../../services/DataCollection/clickActions';

const FeedbackComponent = ({ t, props }) => {
  const [close, setClose] = useState(false);

  useEffect(() => {
    captureClickEvents(
      AnalyticsClickEventsList.FEEDBACK_SURVEY,
      props?.printerSubscription?.entityStartDate,
      props?.printerSubscription?.subscriptionId,
      props?.cancellationPeriod
    );
  }, [
    props?.cancellationPeriod,
    props?.printerSubscription?.entityStartDate,
    props?.printerSubscription?.subscriptionId
  ]);

  const openSurvey = () => {
    window.QSI.API.run();

    setClose(true);
  };

  return (
    <>
      {!close && (
        <FeedbackContainer>
          <Container>
            <InnerContainer>
              <CartTextContainer>
                <CardTitle>
                  {t(
                    'Feedback.title',
                    'Sorry to see you go. Tell us about your experience'
                  )}
                </CardTitle>

                <CardSubTitle>
                  {t(
                    'Feedback.subtitle',
                    'Your opinion matters, help us improve by taking a quick survey'
                  )}
                </CardSubTitle>
              </CartTextContainer>
              <ButtonContainer>
                <Button
                  onClick={openSurvey}
                  appearance="primary"
                  data-testid="feedback-button"
                  id="openEmbeddedSurvey"
                >
                  {t('Feedback.button', 'Take survey')}
                </Button>
              </ButtonContainer>
            </InnerContainer>
          </Container>
        </FeedbackContainer>
      )}
    </>
  );
};

export default FeedbackComponent;
