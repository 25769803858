import React, { useEffect, useMemo, useState } from 'react';
import {
  Container,
  CardContainer,
  CardTitle,
  CardLinkText,
  TextContainer,
  Image,
  CardLabelText,
  CardLabelTextContent,
  CardLine,
  StepperBlock,
  StepOne,
  StepLine,
  HrLine,
  Card,
  Content,
  ContentTitle,
  CardOneContainer,
  StepOneContent,
  Label,
  StepThreeContent,
  StepThreeContentText,
  StepContentTitle,
  ButtonCantainer,
  StepThreeText,
  SubmitBlock,
  PaperCardContainer,
  PaperCardTitle,
  InsideCardOne,
  ResumePlanTitle,
  InsideCardTwo,
  HrLineTwo,
  ResumeLink
} from './Style';
import {
  Button,
  IconCheckmarkCircle,
  IconCircle,
  IconReload,
  IconShoppingCart,
  IconSpreadsheet
} from '@veneer/core';
import Images from '../../../assets/images';
import PaperFeedbackComponent from '../CommonComponent/FeedbackComponent';
import { internalURLs } from '../../configs/internal-link';
import PrintPlanChange from '../CommonComponent/PrintPlanChange';
import useModifiedUrl from 'src/hooks/useModifiedUrl';
import { SubscriptionsClient } from 'src/api/SubscriptionClient';
import { paperRevertSubscription } from 'src/utils/paperRevertSubscription';
import PaperReactivationModal from './PaperReactivationModal/PaperReactivationModal';
import moment from 'moment';
import { PendingChangesClient } from 'src/services/SubscriptionsStateClient';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { redirectURLs } from '../../configs/external-link';
import { PaperLoadingComponent } from '../CommonComponent/PaperLoadingComponent/PaperLoadingComponent';
import LoadingPage from '../CommonComponent/LoaderComponent';
const PaperSecondComponent = ({
  t,
  props,
  navigation,
  authProvider,
  stack,
  bizlogicId
}) => {
  const [isRescindCancelEnabled, setIsRescindCancelEnabled] =
    useState<boolean>(false);
  const [optionId, setOptionId] = useState<string>('');
  const [canceledDate, setCanceledDate] = useState<any>();
  const [optionLoading, setOptionLoading] = useState<boolean>(false);
  const [pendingLoading, setPendingLoading] = useState<boolean>(false);
  const finalPath = useModifiedUrl(internalURLs.PrinterOverviewURL);
  const printHistoryPath = useModifiedUrl(internalURLs.PrintHistory);
  const subscriptionClient = useMemo(
    () => new SubscriptionsClient(authProvider, stack, bizlogicId),
    [authProvider, stack, bizlogicId]
  );
  function formatDateString(date) {
    return moment(date).format('MMM DD, YYYY');
  }
  const billingEndDate = props.cancellationBillingDate;

  const after1DayOfBilling = formatDateString(
    moment(props?.cancellationBillingDate).add(1, 'days')
  );
  const statusReturn = moment().isAfter(billingEndDate);
  const statusComplete = moment().isAfter(after1DayOfBilling);
  const flags = useFlags();

  useEffect(() => {
    async function fetchData() {
      setOptionLoading(true);
      try {
        const subscriptionDetailsDataFetch =
          await subscriptionClient.getSubscriptionOptions(
            props?.instantPaperEntityDetails?.subscriptionId,
            props?.instantPaperEntityDetails?.entityId
          );

        const rescindCancelData = paperRevertSubscription(
          subscriptionDetailsDataFetch,
          props?.instantPaperEntityDetails
        );
        if (rescindCancelData.period.length !== 0) {
          setIsRescindCancelEnabled(true);
          setOptionId(rescindCancelData.period[0].optionId);
        } else {
          setIsRescindCancelEnabled(false);
          setOptionId('');
        }
        setOptionLoading(false);
      } catch (e) {
        console.log('Error', e);
        setOptionLoading(false);
      }
    }

    if (props?.printerSubscription?.subscriptionId) fetchData();
  }, [props, subscriptionClient]);
  async function getOrderDate(pendingData) {
    const pendingCancellation = pendingData?.data?.contents.filter(
      (content) =>
        content.type === 'cancellation' &&
        content.reason.type === 'instantPaperCancellation'
    );

    const cancellationStartedValues = pendingCancellation[0].createdAt;
    const cancelDate = moment(cancellationStartedValues.split('T')[0]).format(
      'MMM DD, YYYY'
    );

    setCanceledDate(cancelDate);
  }
  useEffect(() => {
    async function fetchData() {
      setPendingLoading(true);
      const pendingChangesClient = new PendingChangesClient(
        authProvider,
        stack
      );
      try {
        const pendingDataFetch = await pendingChangesClient.getPendingChanges(
          props?.instantPaperEntityDetails?.subscriptionId
        );
        await getOrderDate(pendingDataFetch);
        setPendingLoading(false);
      } catch (e) {
        setPendingLoading(false);
        console.log('Error', e);
      }
    }

    if (props?.printerSubscription?.subscriptionId) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.printerSubscription, authProvider, stack]);
  const isLoading = optionLoading || pendingLoading;

  return (
    <>
      {isLoading ? (
        props?.directlyLoadSecondComponent ? (
          <LoadingPage />
        ) : (
          <PaperLoadingComponent
            t={t}
            props={props}
            isLoading={isLoading}
          />
        )
      ) : (
        <Container>
          <CardContainer>
            <TextContainer>
              <IconCheckmarkCircle
                size={48}
                filled
              />
              <CardTitle>
                {t(
                  'Paper.SecondComponent.CardTitle',
                  'Your Paper Add-on Service removal request is confirmed'
                )}
              </CardTitle>
              {isRescindCancelEnabled && (
                <ResumeLink>
                  <CardLinkText>
                    {t(
                      'Paper.SecondComponent.CardLinkText',
                      'Changed your mind? '
                    )}
                  </CardLinkText>
                  <PaperReactivationModal
                    data-testid="Resume your Paper Add-On Service."
                    t={t}
                    link={t('Paper.SecondComponent.Link', 'Resume')}
                    navigation={navigation}
                    props={props}
                    authProvider={authProvider}
                    stack={stack}
                    optionId={optionId}
                    bizlogicId={bizlogicId}
                    showModel={true}
                  />{' '}
                  <CardLinkText>
                    {t('Paper.SecondComponent.ResumeDate', 'any time before')}{' '}
                    {billingEndDate}
                  </CardLinkText>
                </ResumeLink>
              )}
            </TextContainer>

            <Image
              src={Images.PaperPrinterPlaceHolderImage}
              alt="Paper printer Logo"
            ></Image>
          </CardContainer>

          {props.openSurvey && (
            <PaperFeedbackComponent
              t={t}
              data-testid="feedback"
            />
          )}

          <CardLabelText>
            {t('SecondComponent.CardLabelText', 'Here’s what happens next')}
          </CardLabelText>
          <CardLabelTextContent>
            {t(
              'SecondComponent.CardText2',
              'We’ve also emailed you a copy of this schedule for reference.'
            )}
          </CardLabelTextContent>
          <CardLine />
          {/* Steeper Block */}
          <StepperBlock>
            <StepOne>
              <StepLine>
                <IconCheckmarkCircle
                  size={36}
                  filled
                />
                <HrLine />
              </StepLine>
              <Card>
                <Content>
                  <ContentTitle>
                    {canceledDate}
                    {':'}{' '}
                    {t(
                      'Paper.SecondComponent.StepOne.Title',
                      'Paper removal submitted'
                    )}
                  </ContentTitle>
                  <CardOneContainer>
                    <StepOneContent>
                      {t(
                        'Paper.SecondComponent.StepOne.Content',
                        'The Paper Add-on Service removal process starts.'
                      )}
                    </StepOneContent>
                  </CardOneContainer>
                </Content>
              </Card>
            </StepOne>
            <StepOne>
              <StepLine>
                {statusReturn ? (
                  <>
                    <IconCheckmarkCircle
                      size={36}
                      filled
                    />
                  </>
                ) : (
                  <>
                    <IconCircle
                      data-testid="icon-circle"
                      size={36}
                      style={{ color: '#666' }}
                    />
                    <Label>{t('SecondComponent.label.2', '2')}</Label>
                  </>
                )}
                <HrLineTwo />
              </StepLine>

              <Card>
                <StepThreeContent>
                  <>
                    <ContentTitle>
                      {billingEndDate} {':'}{' '}
                      {t(
                        'Paper.SecondComponent.StepTwo.Title',
                        'Paper removal from plan complete'
                      )}
                    </ContentTitle>
                    <div>
                      <StepThreeContentText>
                        {t(
                          'Paper.SecondComponent.StepTwo.Content',
                          'Until this date, you can still'
                        )}{' '}
                        {isRescindCancelEnabled && (
                          <PaperReactivationModal
                            data-testid="Resume your Paper Add-On Service."
                            t={t}
                            link={t(
                              'Paper.SecondComponent.StepTwo.Link',
                              'resume the Paper Add-on Service.'
                            )}
                            navigation={navigation}
                            props={props}
                            authProvider={authProvider}
                            stack={stack}
                            optionId={optionId}
                            bizlogicId={bizlogicId}
                            showModel={false}
                          />
                        )}
                      </StepThreeContentText>
                    </div>
                    <ButtonCantainer>
                      <Button
                        appearance="secondary"
                        leadingIcon={<IconShoppingCart />}
                        onClick={() => {
                          window.open(
                            redirectURLs.hpShop,
                            '_blank',
                            'noopener'
                          );
                        }}
                      >
                        {' '}
                        {t(
                          'Paper.SecondComponent.StepTwo.Button',
                          'Shop HP paper'
                        )}
                      </Button>
                    </ButtonCantainer>
                  </>
                </StepThreeContent>
              </Card>
            </StepOne>
            <StepOne>
              <StepLine>
                {statusComplete ? (
                  <>
                    <IconCheckmarkCircle
                      size={36}
                      filled
                    />
                  </>
                ) : (
                  <>
                    <IconCircle
                      size={36}
                      style={{ color: '#666' }}
                    />
                    <Label>{t('SecondComponent.label.3', '3')}</Label>
                  </>
                )}
              </StepLine>

              <Card>
                <StepThreeContent>
                  <>
                    <StepContentTitle>
                      {after1DayOfBilling}
                      {':'}{' '}
                      {t(
                        'Paper.SecondComponent.StepThree.Title',
                        'Final bill charged with Paper Add-On Service'
                      )}
                    </StepContentTitle>

                    <StepThreeText>
                      {t(
                        'Paper.SecondComponent.StepThree.Content',
                        'You are charged for your Paper Add-on Service one final time.'
                      )}
                    </StepThreeText>

                    {flags?.enablePrintHistoryLinkPostEnrollment && (
                      <ButtonCantainer>
                        <Button
                          appearance="secondary"
                          leadingIcon={<IconSpreadsheet />}
                          onClick={() => {
                            navigation.push(printHistoryPath);
                          }}
                        >
                          {' '}
                          {t(
                            'Paper.SecondComponent.StepThree.Button',
                            'Preview upcoming bill'
                          )}
                        </Button>
                      </ButtonCantainer>
                    )}
                  </>
                </StepThreeContent>
              </Card>
            </StepOne>
          </StepperBlock>

          <SubmitBlock>
            <Button
              data-testid="Return to Overview"
              onClick={() => {
                navigation.push(finalPath);
              }}
            >
              {t('Paper.SecondComponent.ReturnButton', 'Return to Overview')}
            </Button>
          </SubmitBlock>

          <PaperCardContainer>
            <PaperCardTitle>
              {t(
                'Paper.SecondComponent.PaperCardTitle',
                'Considered all your options?'
              )}
            </PaperCardTitle>
            <CardLine />
            {isRescindCancelEnabled && (
              <InsideCardOne>
                <IconReload size={40} />
                <div>
                  <ResumePlanTitle>
                    {t(
                      'Paper.SecondComponent.ResumePlanTitle',
                      'Resume Paper Add-on Service?'
                    )}
                  </ResumePlanTitle>
                  <PaperReactivationModal
                    data-testid="Resume your Paper Add-On Service."
                    t={t}
                    link={t(
                      'Paper.SecondComponent.ResumePlanLink',
                      'Keep subscription as it was'
                    )}
                    navigation={navigation}
                    props={props}
                    authProvider={authProvider}
                    stack={stack}
                    optionId={optionId}
                    bizlogicId={bizlogicId}
                    showModel={false}
                  />
                </div>
              </InsideCardOne>
            )}
            {isRescindCancelEnabled && <CardLine />}
            <InsideCardTwo>
              <PrintPlanChange
                navigation={navigation}
                t={t}
                componentType="PaperSecondComponent"
              />
            </InsideCardTwo>
          </PaperCardContainer>
        </Container>
      )}
    </>
  );
};

export { PaperSecondComponent };
