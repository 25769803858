import styled from 'styled-components';
import { tokens } from '@veneer/tokens';

const { white, gray12 } = tokens.color;
const {
  cornerRadius4,
  size2: layoutSize2,
  size4: layoutSize4,
  mdMin,
  size8
} = tokens.layout;
const { family0, size7, lineHeight7 } = tokens.typography;
export const PaperLoadingCard = styled.div`
  margin: 40px;
  padding: ${size8};
  background: ${white};
  border-radius: ${cornerRadius4};
`;
export const DeviceCardLoader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const PaperDeviceCard = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  align-items: flex-start;
  gap: ${layoutSize2};
  @media (max-width: ${mdMin}) {
    width: 100%;
  }
`;
export const CardTitle = styled.p`
  font-family: ${family0};
  font-size: ${size7};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight7};
  color: ${gray12};
`;

export const PaperImage = styled.img`
  display: flex;
  width: 420px;
  height: 236px;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: ${mdMin}) {
    display: none;
  }
`;
export const ProgeressButtonGroup = styled.div`
  width: 100%;

  button {
    width: 100%;
    @media (max-width: ${mdMin}) {
      display: flex;
      flex-direction: row;
      text-wrap: wrap;
    }
  }
  @media (min-width: 300px) {
    display: flex;
    flex-direction: column;
    gap: ${layoutSize4};
  }
  @media (min-width: 640px) {
    display: flex;
    gap: ${layoutSize4};
    flex-direction: row;
  }
  @media (min-width: 1100px) {
    padding-bottom: 0px;
  }
`;
