import React, { useEffect, useMemo, useState } from 'react';
import LoaderPage from '../LoaderComponent';
import {
  Card,
  CardContainer,
  CardTitle,
  Line,
  InsideCardOne,
  InsideCardTwo
} from './Style';
import CustomerSupport from '../CommonComponent/customerSupportComponent';
import PrintPlanChange from './PrintPlanChange';
import { SubscriptionsClient } from 'src/api/SubscriptionClient';
import { getPayloadWithOptionId } from 'src/types/subscription';
import ApiErrorState from '../ApiErrorState';
import SecondComponent from '../SecondComponent/SecondComponent';
import CancelDeviceInfo from './CancelDeviceInfo/CancelDeviceInfo';
import { captureClickEvents } from '../../../services/DataCollection/clickActions';
import { AnalyticsClickEventsList } from '../../../constants';

const FirstComponent = ({
  t,
  authProvider,
  stack,
  props,
  navigation,
  openSecondComponent,
  bizlogicId
}) => {
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const subscriptionClient = useMemo(
    () => new SubscriptionsClient(authProvider, stack, bizlogicId),
    [authProvider, bizlogicId, stack]
  );

  const openSecond = async () => {
    try {
      setIsLoading(true);

      await subscriptionClient.updateSubscription(
        props?.printerSubscription?.subscriptionId,
        getPayloadWithOptionId(props?.cancellationPeriod[0].optionId)
      );
      openSecondComponent();
      setIsLoading(false);
      setSuccess(true);
    } catch (e) {
      console.log('Error', e);
      setSuccess(false);
      setError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props?.cancellationPeriod) {
      captureClickEvents(
        AnalyticsClickEventsList.SCREEN_DISPLAY_FIRST,
        props?.printerSubscription?.entityStartDate,
        props?.printerSubscription?.subscriptionId,
        props?.cancellationPeriod
      );
    }
  }, [props?.cancellationPeriod, props?.printerSubscription]);

  return (
    <>
      {isLoading && <LoaderPage />}
      {error && (
        <ApiErrorState
          props={props}
          {...{ t }}
          navigation={navigation}
        />
      )}
      {success &&
        (props?.openSecond ? (
          <>
            {props?.openSecond && (
              <SecondComponent
                t={t}
                props={props}
                navigation={navigation}
                authProvider={authProvider}
                stack={stack}
                bizlogicId={bizlogicId}
              />
            )}
          </>
        ) : (
          <Card>
            <CancelDeviceInfo
              t={t}
              props={props}
              navigation={navigation}
              openSecond={openSecond}
              loading={isLoading}
            />
            {/* PrintPlanChange and CustomerSupport Component */}
            <CardContainer>
              <CardTitle>
                {t('FirstComponent.title', 'You don’t have to cancel')}
              </CardTitle>
              <Line />
              <InsideCardOne>
                <PrintPlanChange
                  startDate={props?.printerSubscription?.entityStartDate}
                  subscriptionId={props?.printerSubscription?.subscriptionId}
                  navigation={navigation}
                  t={t}
                  trialPeriod={props?.cancellationPeriod}
                />
              </InsideCardOne>
              <Line />
              <InsideCardTwo>
                <CustomerSupport
                  props={props}
                  t={t}
                  captureEvent={AnalyticsClickEventsList.SUPPORT_FIRST}
                />
              </InsideCardTwo>
            </CardContainer>
          </Card>
        ))}
    </>
  );
};

export default FirstComponent;
