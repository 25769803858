import React from 'react';
import {
  BannerImage,
  BannerWrapper,
  LeftBlock,
  RescindText,
  RightBlock,
  Title
} from './styles';
import { IconCheckmarkCircle } from '@veneer/core';
import ReactiveSubscriptionModal from '../ReactiveSubscriptionModal/ReactiveSubscriptionModal';
import Images from 'src/assets/images';

const Banner = ({
  t,
  openSubscriptionModal,
  navigation,
  props,
  link,
  authProvider,
  stack,
  optionId,
  bizlogicId
}) => {
  return (
    <BannerWrapper>
      <LeftBlock>
        <IconCheckmarkCircle
          size={48}
          filled
        />
        <Title>
          {t(
            'SecondComponent.CardTitle',
            'Your cancellation request is confirmed'
          )}
        </Title>
        {openSubscriptionModal && (
          <RescindText>
            {t('SecondComponent.CardLinkText', 'Change your mind? ')}

            <ReactiveSubscriptionModal
              t={t}
              link={link}
              navigation={navigation}
              props={props}
              authProvider={authProvider}
              stack={stack}
              optionId={optionId}
              bizlogicId={bizlogicId}
            />
          </RescindText>
        )}
      </LeftBlock>
      <RightBlock>
        <BannerImage
          src={Images.CancellationImage}
          alt="cancellation_banner_image"
        />
      </RightBlock>
    </BannerWrapper>
  );
};

export default Banner;
