import styled from 'styled-components';
import { tokens } from '@veneer/tokens';
const {
  cornerRadius4,
  size4: layoutSize4,
  size8: layoutSize8,
  size6: layoutSize6,
  size3: layoutSize3,
  size1: layoutSize1
} = tokens.layout;
const { white, gray6, gray12, gray3, hpBlue7 } = tokens.color;
const { lineHeight5, size3, size2, lineHeight3, lineHeight2, size5, family0 } =
  tokens.typography;

export const Card = styled.div`
  margin: 40px;
  padding: 40px;
  background: ${white};
  border-radius: ${cornerRadius4};
  font-family: ${family0};
`;

export const CardContainer = styled.div`
  padding: ${layoutSize8};
  border-radius: ${cornerRadius4};
  border: 1px solid ${gray6};
  background: ${white};
`;
export const CardTitle = styled.p`
  font-family: ${family0};
  font-size: ${size5};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight5};
  color: ${gray12};
  padding-bottom: ${layoutSize3};
`;
export const Line = styled.div`
  border-top: 1px solid ${gray3};
`;
export const InsideCardOne = styled.div`
  display: flex;
  gap: ${layoutSize4};
  padding-top: ${layoutSize6};
  padding-bottom: ${layoutSize4};
`;
export const InsideCardTwo = styled.div`
  display: flex;
  gap: ${layoutSize4};
  padding-top: ${layoutSize4};
`;
export const CardLabel = styled.p`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size3};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
  margin-top: ${layoutSize1};
`;
export const CardText = styled.p`
  color: ${hpBlue7};
  font-family: ${family0};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
`;
