import React from 'react';
import { BoldText, CancellationText } from '../styles';

const CancellationNonRemorseContent = ({ t, props, cancelationModal }) => (
  <CancellationText data-testid="cancellationNonRemorse">
    {t('FirstComponent.Description.Content3', 'You will be charged')}{' '}
    <BoldText>{props?.cancellationPeriodCost}</BoldText>{' '}
    {t('FirstComponent.Description.Content4', 'and the')}{' '}
    <BoldText>
      {t('FirstComponent.Description.Content5', 'printer will stop working on')}{' '}
      {props?.cancellationBillingDate}.
    </BoldText>
    <CancellationText margin="0">
      {t('FirstComponent.Description.Content8', 'See more info about')}
      {cancelationModal}
    </CancellationText>
  </CancellationText>
);

export default CancellationNonRemorseContent;
