import React, { useEffect, useState, useMemo } from 'react';
import App from 'src/components';
import resources from 'src/assets/locale';
import { ShellProps } from 'src/types/shell';
import { TranslatorFunctionType } from 'src/types/localization';
import projectNames from './configs/projectNames';
import { MfePropsType } from 'src/types/mfeProps';
import { createApolloClient } from 'src/services/ApolloClient';
import { ApolloProvider } from '@apollo/client';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import setEnvironmentVariables from 'src/helpers/setEnvironmentVariables';

declare global {
  interface Window {
    Shell: ShellProps;
    QSI: any;
  }
}
type CancellationProps = {
  country?: string;
  language?: string;
};

/**
 * @function Root Main function
 * @param props
 * @returns
 */

export default function Root({ ...props }: MfePropsType & CancellationProps) {
  const [t, setT] = useState<TranslatorFunctionType>();
  const [authToken, setAuthToken] = useState<string>('');
  const interfaces = window.Shell?.v1;
  const graphqlClient = useMemo(
    () => createApolloClient(authToken, props?.stack),
    [authToken, props?.stack]
  );
  // Create a translator function by providing the resource files
  useEffect(() => {
    interfaces?.localization
      .createTranslatorFunction(resources)
      .then((v) => setT(() => v));
    if (interfaces?.authProvider) {
      interfaces?.authProvider?.getAccessToken().then((res) => {
        if (res?.length) setAuthToken(res);
      });
    }
  }, [interfaces]);

  // Wait for the translator function creation to show the content
  if (!t) {
    return null;
  }
  const { stack } = props;
  const envirnoment = setEnvironmentVariables(stack);
  const launchDarklyClientId = envirnoment.LAUNCHDARKLY_CLIENTSIDE_ID;
  const bizlogicId = envirnoment.ONBOOKS_BL_ID;
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <ApolloProvider client={graphqlClient}>
        <LDProvider
          clientSideID={launchDarklyClientId || '624769cf7b765b1471f2d115'}
          user={{ key: 'test@email.com' }}
        >
          <App {...{ ...props, ...interfaces, t, bizlogicId }} />
        </LDProvider>
      </ApolloProvider>
    </section>
  );
}
