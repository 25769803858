import styled from 'styled-components';
import { tokens } from '@veneer/tokens';
const {
  cornerRadius4,
  size5: layoutSize5,
  size4: layoutSize4,
  size8: layoutSize8,
  size6: layoutSize6,
  size3: layoutSize3,
  size8,
  mdMin,
  smMax
} = tokens.layout;
const { white, gray6, gray12, gray3, black } = tokens.color;
const { lineHeight5, size2, size5, lineHeight3, family0, size7, lineHeight7 } =
  tokens.typography;

export const Card = styled.div`
  margin: 40px;

  padding: ${size8};
  background: ${white};
  border-radius: ${cornerRadius4};
`;

export const DeviceCard = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  align-items: flex-start;
  gap: ${layoutSize4};
  @media (max-width: ${mdMin}) {
    width: 100%;
  }
`;
export const CardTitle = styled.p`
  font-family: ${family0};
  font-size: ${size7};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight7};
  color: ${gray12};
`;
export const DeviceBlock = styled.div``;
export const DeviceBlockAlignment = styled.div`
  margin-bottom: ${layoutSize5};
  @media (min-width: 640px) {
    margin-bottom: 0px;
  }
`;
export const Image = styled.img`
  display: flex;

  justify-content: flex-end;
  align-items: center;

  @media (max-width: ${mdMin}) {
    display: none;
  }
`;
export const DeviceCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ButtonGroup = styled.div`
  margin-top:${layoutSize4};
   button {
    @media (max-width: ${mdMin}){
      display: flex;s
      flex-direction: row;
      text-wrap: wrap;
    }
  }
  @media (min-width: 300px) {
    display: flex;
    flex-direction: column;
    gap: ${layoutSize4};
  }
  @media (min-width: ${smMax}) {
    display: flex;
    gap: ${layoutSize4};
    flex-direction: row;
  }
  @media (min-width: 1100px) {
    padding-bottom: 0px;
  }
`;
export const CardContainer = styled.div`
  padding: ${layoutSize8};
  border-radius: ${cornerRadius4};
  border: 1px solid ${gray6};
  background: ${white};
`;

export const Line = styled.div`
  border-top: 1px solid ${gray3};
`;
export const InsideCardOne = styled.div`
  display: flex;
  gap: ${layoutSize4};
  padding-top: ${layoutSize6};
  padding-bottom: ${layoutSize6};
`;
export const InsideCardTwo = styled.div`
  display: flex;
  gap: ${layoutSize4};
  padding-top: ${layoutSize4};
`;
export const PaperCardTitle = styled.p`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size5};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight5};
  margin-bottom: ${layoutSize3};
`;
export const PaperFooterText = styled.p`
  color: ${black};
  font-family: ${family0};
  font-size: ${size2};
  font-weight: 400;
  line-height: ${lineHeight3};
  margin-top: ${layoutSize4};
  margin-bottom: ${layoutSize6};
`;
