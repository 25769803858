import styled from 'styled-components';
import tokens from '@veneer/tokens';

const { color, layout, typography } = tokens;

export const BannerWrapper = styled.div`
  @media (min-width: 1100px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
  }
`;

export const LeftBlock = styled.div``;

export const RightBlock = styled.div``;

export const Title = styled.p`
  color: ${color.gray12};
  font-size: ${typography.size7};
  line-height: ${typography.lineHeight7};
  margin: ${layout.size5} 0 ${layout.size3} 0;
`;

export const RescindText = styled.div`
  display: flex;
  align-items: center;
  gap: ${layout.size1};
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight2};
`;

export const BannerImage = styled.img`
  width: 230px;
  height: 180px;
  @media (min-width: 500px) {
    width: 360px;
    height: 200px;
  }
  @media (min-width: 640px) {
    width: 420px;
    height: 236px;
  }
`;
