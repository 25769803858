import React, { useState } from 'react';
import {
  FeedbackContainer,
  FeedBackTextContainer,
  FeedbackCardTitle,
  FeedbackCardSubTitle,
  FeedbackButtonContainer,
  FeedbackInnerContainer
} from './Styles';
import { Button } from '@veneer/core';

const PaperFeedbackComponent = ({ t }) => {
  const [close, setClose] = useState(false);
  const openSurvey = () => {
    window.QSI.API.run();
    setClose(true);
  };
  return (
    <>
      {!close && (
        <FeedbackContainer>
          <FeedbackInnerContainer>
            <FeedBackTextContainer>
              <FeedbackCardTitle>
                {t(
                  'Paper.Feedback.papertitle',
                  'Tell us about your experience'
                )}
              </FeedbackCardTitle>

              <FeedbackCardSubTitle>
                {t(
                  'Paper.Feedback.subtitle',
                  'Your opinion matters! Help us build a better experience by taking a quick survey.'
                )}
              </FeedbackCardSubTitle>
            </FeedBackTextContainer>
            <FeedbackButtonContainer>
              <Button
                onClick={openSurvey}
                appearance="primary"
                data-testid="feedback-button"
                id="openPaperEmbeddedSurvey"
              >
                {t('Paper.Feedback.button', 'Take survey')}
              </Button>
            </FeedbackButtonContainer>
          </FeedbackInnerContainer>
        </FeedbackContainer>
      )}
    </>
  );
};

export default PaperFeedbackComponent;
