import styled from 'styled-components';
import { tokens } from '@veneer/tokens';

const { hpBlue7 } = tokens.color;
const { size2, lineHeight2, family0 } = tokens.typography;

export const ReactiveSubscriptionCardText = styled.p`
  color: ${hpBlue7};
  font-family: ${family0};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
  cursor: pointer;
`;

export const ReactiveSubscriptionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${size2};
  width: 100%;
`;
