import React, { useEffect } from 'react';
import { captureClickEvents } from '../../../../services/DataCollection/clickActions';
import { AnalyticsClickEventsList } from '../../../../constants';
import {
  RecyclingModalContent,
  RecyclingModalCardLabel,
  RecyclingModalCardTitle,
  RecyclingModalIcon,
  RecyclingModalCardContent
} from './Style';
import { IconRecycle, Modal } from '@veneer/core';
const RecyclingModal = ({ t, startDate, subscriptionId, trialPeriod }) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (open) {
      captureClickEvents(
        AnalyticsClickEventsList.RECYCLING_PAGE,
        startDate,
        subscriptionId,
        trialPeriod
      );
    }
  }, [subscriptionId, startDate, open, trialPeriod]);
  return (
    <>
      <a
        href="#"
        data-testid="modal-close-button"
        onClick={() => {
          setOpen(true);
          captureClickEvents(
            AnalyticsClickEventsList.RECYCLING_LINK,
            startDate,
            subscriptionId,
            trialPeriod
          );
        }}
      >
        <RecyclingModalCardLabel>
          {t('RecyclingModal.label', 'recycling.')}
        </RecyclingModalCardLabel>
      </a>
      <Modal
        align="start"
        closeButton
        id="modal-id"
        show={open}
        onClose={() => setOpen(false)}
        data-testid="modal-content1"
      >
        <RecyclingModalContent>
          <RecyclingModalIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
            >
              <circle
                opacity="0.1"
                cx="17.5"
                cy="17.5"
                r="17.5"
                fill="#0076AD"
              />
            </svg>
            <IconRecycle
              size={35}
              style={{ position: 'absolute', top: '10px', left: '8px' }}
            />
          </RecyclingModalIcon>
          <RecyclingModalCardTitle>
            {t('RecyclingModal.title', 'Recycling the printer')}
          </RecyclingModalCardTitle>
        </RecyclingModalContent>
        <RecyclingModalCardContent>
          {t(
            'RecyclingModal.content',
            'Returning the printer helps us incorporate recycled materials into the next generation of new products. Help us reach 75% circulatory products by 2030.'
          )}
        </RecyclingModalCardContent>
      </Modal>
    </>
  );
};

export default RecyclingModal;
