import PrinterPlaceholderImage from 'src/assets/images/printer-placeholder.png';

export function getPlaceholderImage(): string {
  return PrinterPlaceholderImage;
}

export const getDeviceDetailsGraphql = (data: any, sku: string) => {
  const printerBundle = data?.products?.items[0];
  const coreOfferBundle = printerBundle?.items.find(
    (item) => item.title == 'Core Offer'
  );
  const coreOffer = coreOfferBundle?.options?.find(
    (plan) => plan.product.sku === sku
  );
  if (!coreOffer) return null;

  return {
    productSku: coreOffer.product?.sku,
    modelName: coreOffer.product?.hp_short_config_name,
    deviceImage: coreOffer.product?.thumbnail.url,
    printerType: coreOffer.product?.hp_supply_type_label
  };
};
export const getPaperPriceDetails = (data: any, sku: string) => {
  const instantPaperBundle = data?.products?.items[0];
  const coreOfferBundle = instantPaperBundle.items.reduce(
    (accumulator, item) => {
      const filtered = item.options.filter(
        (option) => option.product.hp_product_type_label === 'instantPaper'
      );
      return accumulator.concat(filtered);
    },
    []
  );
  const instantPaper = coreOfferBundle?.find(
    (plan) => plan.product.sku === sku
  );
  if (!instantPaper) return null;

  return {
    price: instantPaper?.product?.price?.regularPrice?.amount?.value
  };
};
