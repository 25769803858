/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button } from '@veneer/core';
import { captureClickEvents } from '../../../services/DataCollection/clickActions';
import { internalURLs } from '../../configs/internal-link';
import { AnalyticsClickEventsList } from '../../../constants';
import LoaderPage from '../LoaderComponent';
import CustomerSupport from '../CommonComponent/customerSupportComponent';
import {
  Container,
  InsideCardTwo,
  LineSupport,
  SupportCard
} from '../SecondComponent/Styles';
import { getCancelledCost } from 'src/utils/cancelAmount';
import FeedbackComponent from '../CommonComponent/FeedbackComponent';
import useModifiedUrl from 'src/hooks/useModifiedUrl';
import useSubscriptionOptions from 'src/hooks/useSubscriptionOptions';
import usePendingChanges from 'src/hooks/usePendingChanges';
import Banner from './Banner';
import RemorseComponent from './RemorseComponent';
import ResumeSubscription from './ResumeSubscription';

function SecondComponent({
  t,
  props,
  navigation,
  authProvider,
  stack,
  bizlogicId
}) {
  const { printerSubscription, cancellationBillingDate, cancellationPeriod } =
    props;
  const { subscriptionId, entityId, entityStartDate } = printerSubscription;

  const {
    isRescindCancelEnabled,
    optionId,
    loading: subscriptionLoading,
    fetchSubscriptionOptions
  } = useSubscriptionOptions(authProvider, stack, bizlogicId);

  const {
    inRemorse,
    after10DaysOfBilling,
    canceledData,
    canceledDate,
    after10days,
    loading: pendingChangesLoading,
    fetchPendingChanges
  } = usePendingChanges(authProvider, stack);

  const finalPath = useModifiedUrl(internalURLs.PrinterOverviewURL);

  const [costValue, setCostValue] = useState<string>('');
  const [cancelOptionName, setCancelOptionName] = useState<string>('');

  const returnToOverview = () => {
    navigation.push(finalPath);
    captureClickEvents(
      AnalyticsClickEventsList.RETURN_TO_DASHBOARD,
      props?.printerSubscription?.entityStartDate,
      props?.printerSubscription?.subscriptionId,
      props?.cancellationPeriod
    );
  };

  const fetchCancellationCost = async () => {
    const { name, cost } = await getCancelledCost(
      canceledData,
      props?.subscriptionOption?.subscription?.cancellation
    );

    setCostValue(cost);
    setCancelOptionName(name);
  };

  useEffect(() => {
    if (canceledData) {
      fetchCancellationCost();
    }
  }, [canceledData]);

  useEffect(() => {
    if (subscriptionId) {
      fetchSubscriptionOptions(subscriptionId, entityId);
      fetchPendingChanges(subscriptionId, cancellationBillingDate);
    }
  }, [subscriptionId, entityId]);

  useEffect(() => {
    captureClickEvents(
      AnalyticsClickEventsList.SCREEN_DISPLAY_SECOND,
      entityStartDate,
      subscriptionId,
      cancellationPeriod
    );
  }, [entityStartDate, subscriptionId, cancellationPeriod, canceledDate]);

  const isLoading = subscriptionLoading || pendingChangesLoading;

  if (isLoading) {
    return <LoaderPage />;
  }

  return (
    <>
      <Container>
        <Banner
          t={t}
          link={t(
            'SecondComponent.CardLink-top',
            'Resume your HP All-In Plan.'
          )}
          navigation={navigation}
          props={props}
          authProvider={authProvider}
          stack={stack}
          optionId={optionId}
          bizlogicId={bizlogicId}
          openSubscriptionModal={!inRemorse && isRescindCancelEnabled}
        />
        {props.openSurvey && (
          <FeedbackComponent
            t={t}
            data-testid="feedback"
            props={props}
          />
        )}
        {props?.isDeviceShipped && (
          <RemorseComponent
            inRemorse={inRemorse}
            t={t}
            canceledDate={canceledDate}
            isLoading={isLoading}
            costValue={costValue}
            props={{
              ...props,
              cancelOptionName: cancelOptionName
            }}
            after10days={after10days}
            after10DaysOfBilling={after10DaysOfBilling}
          />
        )}
        <Button
          onClick={returnToOverview}
          appearance="secondary"
        >
          {t('SecondComponent.button.Return', 'Return to Overview')}
        </Button>
        {!inRemorse && isRescindCancelEnabled && (
          <ResumeSubscription
            t={t}
            navigation={navigation}
            props={props}
            authProvider={authProvider}
            stack={stack}
            optionId={optionId}
            bizlogicId={bizlogicId}
          />
        )}
        <SupportCard>
          <LineSupport />
          <InsideCardTwo>
            <CustomerSupport
              t={t}
              props={props}
              captureEvent={AnalyticsClickEventsList.SUPPORT_SECOND}
            />
          </InsideCardTwo>
        </SupportCard>
      </Container>
    </>
  );
}

export default SecondComponent;
