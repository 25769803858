import React from 'react';

import {
  SupportCardLabel,
  SupportCardText
} from '../customerSupportComponent/Style';
import { IconHeadset } from '@veneer/core';
import { redirectURLs } from '../../../configs/external-link';

const PaperCustomerSupport = ({ t }) => {
  return (
    <>
      <IconHeadset size={40} />
      <div>
        <SupportCardLabel>
          {t('Paper.CustomerSupport.title', 'Have questions or need help?')}
        </SupportCardLabel>
        <a
          href={redirectURLs.hpsupportContactURL}
          target="_blank"
          rel="noreferrer"
          data-testid="Support"
        >
          <SupportCardText>
            {t('Paper.CustomerSupport.subtitle', 'Contact our expert support')}
          </SupportCardText>
        </a>
      </div>
    </>
  );
};

export default PaperCustomerSupport;
