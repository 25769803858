const getPrinterData = async (allPrinters) => {
  const checkActivePrinter = allPrinters.filter(
    (entity) => entity.state === 'active'
  );

  if (checkActivePrinter.length > 0) {
    return checkActivePrinter[0];
  }

  const replacedByEntities = allPrinters.filter(
    (entity) =>
      entity.linkedEntities &&
      entity.linkedEntities?.some(
        (linkedEntity) =>
          linkedEntity?.type === 'replacedBy' &&
          !entity.linkedEntities?.some(
            (otherLinkedEntity) => otherLinkedEntity?.type === 'replacedWith'
          )
      )
  );

  return replacedByEntities[0];
};
export default getPrinterData;
