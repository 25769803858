import styled from 'styled-components';
import tokens from '@veneer/tokens';
const { white } = tokens.color;
export interface ContainerProps {
  $position?: 'absolute' | null;
}
export const CenterContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  ${({ $position }) =>
    $position === 'absolute'
      ? `position: absolute;
    background-color:${white};
    top: 0;
    left: 0;`
      : null}
`;
