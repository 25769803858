import styled from 'styled-components';
import tokens from '@veneer/tokens';
const { size5: layoutSize5, size3, size1: layoutSize1 } = tokens.layout;
const { gray12, gray9 } = tokens.color;
const {
  lineHeight3,

  family0,
  lineHeight1,

  size2,
  size0
} = tokens.typography;

export const PaperDevicesListItem = styled.div`
  padding: 20px 0px 0px 0px;
  gap: ${size3};
  @media (min-width: 500px) {
    display: flex;
    gap: ${layoutSize5};
  }
`;

export const PaperDevicesListItemImage = styled.img`
  width: 120px;
  height: auto;
`;

export const PaperDeviceListItemInfo = styled.div`
  @media (min-width: 500px) {
    margin: 34px 0px;
  }
`;
export const Image = styled.img`
  width: 120px;
  height: auto;
`;
export const PaperCardTitle = styled.p`
  color: ${gray9};
  font-family: ${family0};
  font-size: ${size0};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight1};
`;
export const PaperCardLabel = styled.p`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
  margin-bottom: ${layoutSize1};
`;
