import { PeriodType } from 'src/constants';
import { toCurrency } from '../utils/currency';

export const getCancelledCost = async (canceledOption, allOptions) => {
  const cancelledDate = new Date(canceledOption.createdAt);

  const filteredData = allOptions.filter((item) => {
    const validFromDate = new Date(item.details.validFrom);
    const validUntilDate = new Date(item.details.validUntil);

    return (
      item.details.returnDevice === true &&
      cancelledDate > validFromDate &&
      cancelledDate < validUntilDate
    );
  });
  const cancelledOptionToUse = filteredData[0];

  if (cancelledOptionToUse?.name === PeriodType.TRIAL) {
    return {
      name: cancelledOptionToUse?.name,
      cost: toCurrency(
        cancelledOptionToUse?.outcomes[0]?.outcomes[0]?.value?.cost
      )
    };
  } else {
    return {
      name: cancelledOptionToUse?.name,
      cost: toCurrency(cancelledOptionToUse?.outcomes[0]?.value?.cost)
    };
  }
};
