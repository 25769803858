import React from 'react';
import { Container, CardTitle, CardText, Line, InsideCard } from './Style';
import { Button } from '@veneer/core';
import { internalURLs } from '../../configs/internal-link';
import CustomerSupport from '../CommonComponent/customerSupportComponent';
import { AnalyticsClickEventsList } from '../../../constants';
import useModifiedUrl from 'src/hooks/useModifiedUrl';
const ApiErrorState = ({ t, navigation, props }) => {
  const finalPath = useModifiedUrl(internalURLs.PrinterOverviewURL);
  return (
    <>
      <Container>
        <CardTitle>
          {t('ApiErrorState.title', 'Something went wrong')}
        </CardTitle>
        <CardText>
          {t('ApiErrorState.content', 'Your cancellation didn’t go through.')}
        </CardText>
        <Button
          onClick={() => {
            navigation.push(finalPath);
          }}
          appearance="secondary"
        >
          {t('ApiErrorState.button', 'Return')}
        </Button>
        <Line />
        <InsideCard>
          <CustomerSupport
            props={props?.printerSubscription}
            t={t}
            captureEvent={AnalyticsClickEventsList.SUPPORT_FIRST}
          />
        </InsideCard>
      </Container>
    </>
  );
};

export default ApiErrorState;
