import React from 'react';
import {
  PaperDevicesListItem,
  PaperDeviceListItemInfo,
  PaperCardTitle,
  Image
} from './Style';
import Images from '../../../../assets/images';
const PaperPrinterDetails = ({ props, t }) => {
  return (
    <>
      <PaperDevicesListItem key={`device-list-${props?.productSKU}`}>
        <Image
          src={Images.InkPaperImage}
          alt="Ink Paper Image"
        ></Image>
        <PaperDeviceListItemInfo data-testid="PrinterDetails">
          <PaperCardTitle>
            {' '}
            {t(
              'Paper.FirstComponent.InkPaperSerialNumber',
              '8.5x11in., 20lb, 96 Bright HP Paper'
            )}
          </PaperCardTitle>
        </PaperDeviceListItemInfo>
      </PaperDevicesListItem>
    </>
  );
};

export default PaperPrinterDetails;
