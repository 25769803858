import { useState } from 'react';
import { PendingChangesClient } from 'src/services/SubscriptionsStateClient';
import { getOrderDate, getTrialPeriodData } from 'src/utils/secondComponent';

const usePendingChanges = (authProvider, stack) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [inRemorse, setInRemorse] = useState<boolean>(false);
  const [after10DaysOfBilling, setAfter10DaysOfBilling] = useState<string>('');
  const [canceledData, setCanceledData] = useState<any>(null);
  const [canceledDate, setCanceledDate] = useState<any>(null);
  const [after10days, setAfter10days] = useState<any>(null);

  const pendingChangesClient = new PendingChangesClient(authProvider, stack);

  const fetchPendingChanges = async (
    subscriptionId,
    cancellationBillingDate
  ) => {
    setLoading(true);

    try {
      const pendingChangesData = await pendingChangesClient.getPendingChanges(
        subscriptionId
      );

      const data = pendingChangesData?.data?.contents;
      await getTrialPeriodData(
        data,
        cancellationBillingDate,
        setInRemorse,
        setAfter10DaysOfBilling
      );
      await getOrderDate(
        data,
        setCanceledData,
        setCanceledDate,
        setAfter10days
      );

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('Error', e);
    }
  };

  return {
    loading,
    inRemorse,
    after10DaysOfBilling,
    canceledData,
    canceledDate,
    after10days,
    fetchPendingChanges
  };
};

export default usePendingChanges;
