import {
  Stack,
  StratusClient
} from '@jarvis/web-stratus-client/dist/src/clients/stratus-client';

import type {
  SubscriptionEntityUpdate,
  SubscriptionOptionsResponse
} from '../types/subscription';
import type { JarvisAuthProvider } from '@jarvis/web-http';
import { URLS } from '../services/urlConfig';

export class SubscriptionsClient extends StratusClient {
  apiName = 'ws-hp.com/subscriptions';
  apiVersion = '1';
  timeout = 0;
  stack: Stack; // Declare stack property
  blId = '';

  constructor(authProvider: JarvisAuthProvider, stack: Stack, blId: string) {
    super(URLS[stack].API_URLS.SUBSCRIPTION_BASE_URL, authProvider);
    this.stack = stack;
    this.blId = blId;
  }

  async getSubscriptionOptions(
    subscriptionId: string,
    entityId?: string
  ): Promise<SubscriptionOptionsResponse> {
    let url = `/${this.blId}/subscriptions/${subscriptionId}/options`;
    if (entityId) {
      url += `?entityIds=${entityId}`;
    }
    return this.jarvisWebHttpInstance
      .get({
        url: url,
        timeout: this.timeout
      })
      .then((response) => response.data);
  }

  async updateSubscription(
    subscriptionId: string,
    data: SubscriptionEntityUpdate
  ): Promise<SubscriptionOptionsResponse> {
    return this.jarvisWebHttpInstance
      .patch({
        url: `/${this.blId}/subscriptions/${subscriptionId}`,
        timeout: this.timeout,
        data
      })
      .then((response) => response.data);
  }
}
