import React, { useEffect } from 'react';
import { SurveyContainer } from './styles';
import { checkCondition } from 'src/utils/surveyFunctions';
import setEnvironmentVariables from 'src/helpers/setEnvironmentVariables';

interface PaperEmbeddedSurveyProps {
  subscriptionId: string;
  stack: number;
  countryCode: string;
  languageCode: string;
}

const PaperEmbeddedSurvey = (props: PaperEmbeddedSurveyProps) => {
  const { subscriptionId, stack, countryCode, languageCode } = props;

  const envirnoment = setEnvironmentVariables(stack);
  const paperCancellationSurvey = envirnoment?.PAPER_CANCELLATION_SURVEY;

  function loadScript(src: string): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    if (document.body) {
      document.body.appendChild(script);
    }
  }

  useEffect(() => {
    const e = 100;
    const h = 'r';
    const f = paperCancellationSurvey?.COOKIE_ID;
    const g = paperCancellationSurvey?.SCRIPT_URL;

    try {
      if (checkCondition(e, h, f)) {
        loadScript(g);
      }
    } catch (error) {
      console.error('loadScript error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SurveyContainer role="presentation">
        <p id="subscriptionID">{subscriptionId}</p>
        {/* DO NOT DELETE CONDITIONAL_ID */}
        <p id={paperCancellationSurvey?.CONDITIONAL_ID}>
          {paperCancellationSurvey?.CONDITIONAL_ID}
        </p>
        <p id="countryCode">{countryCode}</p>
        <p id="languageCode">{languageCode}</p>
      </SurveyContainer>
      <div id={paperCancellationSurvey?.DIV_ID} />
    </>
  );
};

export default PaperEmbeddedSurvey;
