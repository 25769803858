import React, { useEffect, useState } from 'react';
import PrinterCancellation from './Subscription';
import PaperCancellation from './Paper';
import { MfePropsType } from 'src/types/mfeProps';
import { TranslatorFunctionType } from 'src/types/localization';

function App({
  t,
  authProvider,
  stack,
  navigation,
  bizlogicId,
  ...props
}: MfePropsType & { t: TranslatorFunctionType }) {
  const [entity, setEntity] = useState('');
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.get('entity') === 'paper') {
      setEntity('paper');
    } else {
      setEntity('default');
    }
  }, []);

  return (
    <div>
      {entity === 'paper' ? (
        <PaperCancellation
          t={t}
          authProvider={authProvider}
          stack={stack}
          navigation={navigation}
          bizlogicId={bizlogicId}
          {...props}
        />
      ) : (
        <PrinterCancellation
          t={t}
          authProvider={authProvider}
          stack={stack}
          navigation={navigation}
          bizlogicId={bizlogicId}
          {...props}
        />
      )}
    </div>
  );
}

export default App;
