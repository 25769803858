import React from 'react';
import {
  ModalContent,
  CancelationDays,
  Price,
  ModalBox,
  ModalSubText,
  ModalCard,
  CurrentStatusLabel
} from './Styles';
import { PeriodType } from '../../../../constants';
import { toCurrency } from '../../../../utils/currency';
const CancelModalFeeCard = ({
  t,
  standardYear1FeeCost,
  standardYear2FeeCost,

  cancelOptionName
}) => {
  const data = [
    {
      title: t('CancelModal.CancelationDays.trialPeriod', 'Up to 30 days:'),
      description: t(
        'CancelModal.ModalContent6',
        'With the return of the undamaged printer, the cancellation fee is waived.'
      ),
      cancelationFee: 0,
      optionName: PeriodType.TRIAL,
      currentSelection: false
    },
    {
      title: t(
        'CancelModal.CancelationDays.standardYear1ReturnDevice',
        'After 31 days and up to 12 months:'
      ),
      description: '',
      cancelationFee: standardYear1FeeCost,
      optionName: PeriodType.STANDARD_YEAR1_RETURN_DEVICE,
      currentSelection: false
    },
    {
      title: t(
        'CancelModal.CancelationDays.standardYear2ReturnDevice',
        'After 12 months and up to 24 months:'
      ),
      description: '',
      cancelationFee: standardYear2FeeCost,
      optionName: PeriodType.STANDARD_YEAR2_RETURN_DEVICE,
      currentSelection: false
    },
    {
      title: t(
        'CancelModal.CancelationDays.standardAfterYear2ReturnDevice',
        'After 24 months:'
      ),
      description: '',
      cancelationFee: 0,
      optionName: PeriodType.STANDARD_AFTER_YEAR2_RETURN_DEVICE,
      currentSelection: false
    }
  ];
  data.forEach((item) => {
    item.currentSelection = item.optionName === cancelOptionName;
  });
  return (
    <ModalCard>
      {data.map((item, index) => (
        <ModalBox
          key={index}
          $currentStatusFlag={item.currentSelection}
        >
          {item.currentSelection && (
            <CurrentStatusLabel>
              {t('CancelModal.CurrentStatus', 'Your current status')}
            </CurrentStatusLabel>
          )}
          <ModalContent $currentStatusFlag={item.currentSelection}>
            <CancelationDays>{item.title}</CancelationDays>
            <Price>
              {item.cancelationFee !== 0
                ? `${toCurrency(item?.cancelationFee)}*`
                : t('CancelModal.No-fee', 'No fee')}
            </Price>
          </ModalContent>
          {item.description && <ModalSubText>{item.description}</ModalSubText>}
        </ModalBox>
      ))}
    </ModalCard>
  );
};

export { CancelModalFeeCard };
