import React, { useEffect, useMemo, useState } from 'react';
import { TranslatorFunctionType } from 'src/types/localization';
import { MfePropsType } from 'src/types/mfeProps';
import { PaperFirstComponent } from '../Paper/PaperFirstComponent/PaperFirstComponent';
import { SubscriptionsClient } from 'src/api/SubscriptionClient';
import useSubscriptionDataFetcher from 'src/hooks/useSubscriptionDataFetcher';
import usePrinterData from 'src/hooks/usePrinterData';
import {
  initialCancelState,
  CancelState
} from '../../types/initialCancelState';
import useSubscriptionId from 'src/hooks/useSubscriptionId';
import { checkPaperCancellationPeriod } from 'src/utils/paperCancelPeriodUtility';
import { useBillingCycle } from 'src/hooks/useBillingCycle';
import LoaderPage from '../../components/Paper/CommonComponent/LoaderComponent';
import { useLazyGetDeviceDetails } from 'src/services/ApolloClient';
import { getPaperPriceDetails } from 'src/common/utils/getDeviceDetails';
import { toCurrency } from 'src/utils/currency';
import PaperEmbeddedSurvey from './CommonComponent/PaperEmbeddedSurvey';
import useAddress from 'src/hooks/useAddress';
import getLocalization from 'src/helpers/getLocalization';
const PaperCancellation = ({
  t,
  authProvider,
  stack,
  navigation,
  bizlogicId
}: MfePropsType & { t: TranslatorFunctionType }) => {
  const [props, setProps] = useState<CancelState>(initialCancelState);
  const [loadingPage, setLoadingPage] = useState(false);
  const [optionSuccess, setOptionSuccess] = useState(false);
  const [graphqlSuccess, setGraphqlSuccess] = useState(false);
  const subscriptionId = useSubscriptionId();
  const [getDeviceDetails] = useLazyGetDeviceDetails();
  const { subscriptionData } = useSubscriptionDataFetcher(
    authProvider,
    stack,
    subscriptionId
  );
  const { printerData, instantInkData, isLoading } =
    usePrinterData(subscriptionData);
  useEffect(() => {
    const parentSku =
      props?.instantPaperEntityDetails?.product?.value?.parentProductSku;
    const productSku = printerData?.product?.value?.productSku;
    const instantInkProductSku = instantInkData?.product?.value?.productSku;
    const instantPaperEntity = subscriptionData?.entities?.find(
      (item) => item.entityType === 'instant-paper'
    );
    setProps((prevData) => ({
      ...prevData,
      parentSKU: parentSku,
      productSKU: productSku,
      instantInkProductSKU: instantInkProductSku,
      printerSubscription: printerData,
      loading: isLoading,
      instantPaperEntityDetails: instantPaperEntity
    }));
  }, [
    subscriptionData,
    printerData,
    instantInkData,
    isLoading,
    props?.instantPaperEntityDetails?.product?.value?.parentProductSku
  ]);
  const subscriptionClient = useMemo(
    () => new SubscriptionsClient(authProvider, stack, bizlogicId),
    [authProvider, stack, bizlogicId]
  );
  useEffect(() => {
    async function fetchData() {
      setLoadingPage(true);
      try {
        const subscriptionDetailsDataFetch =
          await subscriptionClient.getSubscriptionOptions(
            props?.instantPaperEntityDetails?.subscriptionId,
            props?.instantPaperEntityDetails?.entityId
          );
        setLoadingPage(false);
        const periodData = checkPaperCancellationPeriod(
          subscriptionDetailsDataFetch,
          props?.instantPaperEntityDetails
        );
        setProps((prevData) => ({
          ...prevData,
          cancellationPeriod: periodData.period
        }));
        setOptionSuccess(true);
      } catch (e) {
        console.log('Error', e);
        setLoadingPage(false);
      }
    }
    if (
      props?.instantPaperEntityDetails?.subscriptionId !== undefined &&
      bizlogicId
    )
      fetchData();
  }, [props?.instantPaperEntityDetails, subscriptionClient, bizlogicId]);
  const [getBillingCycles, { billingDate, invoiceDate }] = useBillingCycle(
    authProvider,
    stack,
    props
  );
  useEffect(() => {
    if (props?.printerSubscription?.commerce?.value?.subscriptionId)
      getBillingCycles();
  }, [props?.printerSubscription]);

  useEffect(() => {
    setProps((prevData) => ({
      ...prevData,
      cancellationBillingDate: billingDate,
      cancellationInvoicedDate: invoiceDate
    }));
  }, [billingDate, invoiceDate]);
  useEffect(() => {
    const isDeactivating =
      props?.instantPaperEntityDetails?.state === 'deactivating';
    if (isDeactivating) {
      setProps((prevData) => ({
        ...prevData,
        openSecond: true,
        directlyLoadSecondComponent: true
      }));
    }
  }, [props?.instantPaperEntityDetails?.state]);
  useEffect(() => {
    const fetchDeviceDetails = async () => {
      /* istanbul ignore next */
      await getDeviceDetails({
        variables: { parentSKU: props.parentSKU },
        onError: () => {
          setGraphqlSuccess(false);
        },
        onCompleted: (response) => {
          const priceDetails = getPaperPriceDetails(
            response,
            props?.instantPaperEntityDetails?.product?.value?.productSku
          );
          setProps((prevData) => ({
            ...prevData,
            instantPaperPriceDetails: toCurrency(priceDetails?.price)
          }));
          setGraphqlSuccess(true);
        }
      });
    };
    if (
      props?.parentSKU &&
      props?.instantPaperEntityDetails?.product?.value?.productSku
    )
      fetchDeviceDetails();
  }, [
    getDeviceDetails,
    props.parentSKU,
    props?.instantPaperEntityDetails?.product?.value?.productSku
  ]);
  const isSuccess = optionSuccess && graphqlSuccess;
  const openSecondComponent = () => {
    setProps((prevData) => ({
      ...prevData,
      openSecond: true,
      openSurvey: true
    }));
  };

  const shippingAddressId = props?.printerSubscription?.shippingAddressId;

  const { address: addressData, loading: isAddressLoading } = useAddress(
    shippingAddressId,
    authProvider,
    stack
  );
  const { language } = getLocalization();

  return (
    <>
      {loadingPage && isAddressLoading && <LoaderPage />}

      <PaperEmbeddedSurvey
        subscriptionId={props?.printerSubscription?.subscriptionId}
        stack={stack}
        countryCode={addressData?.countryCode}
        languageCode={language}
      />

      {isSuccess && (
        <PaperFirstComponent
          t={t}
          navigation={navigation}
          props={props}
          openSecondComponent={openSecondComponent}
          authProvider={authProvider}
          stack={stack}
          bizlogicId={bizlogicId}
        />
      )}
    </>
  );
};

export default PaperCancellation;
