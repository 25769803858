import styled from 'styled-components';
import { tokens } from '@veneer/tokens';
const {
  family0,
  size5,
  size3,
  lineHeight5,
  lineHeight2,
  size4,
  lineHeight1,
  size2,
  size7,
  lineHeight3,
  lineHeight7,
  size1
} = tokens.typography;
const { gray12, gray3, gray9, gray6, white, hpBlue7, gray7 } = tokens.color;
const {
  size3: layoutSize3,
  size6: layoutSize6,
  size4: layoutSize4,
  size1: layoutSize1,
  size8: layoutSize8,
  size5: layoutSize5,
  size0: layoutSize0,
  size2: layoutSize2,
  mdMin,
  xsMax,
  cornerRadius4
} = tokens.layout;
interface StyledCardProps {
  $statusReturn?: boolean;
}
export const Container = styled.div`
  margin-top: 40px;
  margin-right: ${layoutSize8};
  margin-left: ${layoutSize8};
  margin-bottom: ${layoutSize8};
  padding: ${layoutSize8};
  background: ${white};
  border-radius: ${cornerRadius4};
`;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CardTitle = styled.p`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size7};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight7};
  margin: ${layoutSize5} ${layoutSize0};
`;
export const CardLinkText = styled.span`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
`;
export const Link = styled.a`
  color: ${hpBlue7};
  font-family: ${family0};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
`;
export const LinkTwo = styled.a`
  color: ${hpBlue7};
  font-family: ${family0};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
`;
export const TextContainer = styled.div`
  margin-bottom: 48px;
  width: 50%;
  @media (max-width: ${mdMin}) {
    width: 100%;
  }
`;
export const Image = styled.img`
  width: auto;
  height: auto;

  @media (max-width: ${mdMin}) {
    display: none;
  }
`;

export const CardLabelText = styled.p`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size5};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight5};
`;
export const CardLabelTextContent = styled.div`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size1};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
  margin-top: ${layoutSize2};
  margin-bottom: ${layoutSize2};
`;
export const CardLine = styled.div`
  border: 1px solid ${gray3};
`;
export const StepperBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${layoutSize8};
  gap: ${layoutSize8};
`;
export const StepOne = styled.div`
  display: flex;
  flex-direction: row;
`;
export const StepLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
export const HrLine = styled.hr`
  border: 1px solid #212121;
  margin: ${layoutSize3} 0 ${layoutSize3} 0;
  @media (max-width: 375px) {
    height: 58px;
    border: 1px solid #212121;
  }
  @media (min-width: 375px) {
    height: 36px;
    border: 1px solid #212121;
  }
`;
export const HrLineTwo = styled.hr<StyledCardProps>`
  
  border:${(props) =>
    props.$statusReturn ? '1px solid #212121' : '1px solid #dbdbdb'}
  margin: ${layoutSize3} 0 ${layoutSize3} 0;
  @media (max-width: 375px) {
    height: 275px;
    border:${(props) =>
      props.$statusReturn ? '1px solid #212121' : '1px solid #dbdbdb'}
  }
  @media (max-width: 575px) {
    height: 156px;
    margin-bottom: 0px;
    border:${(props) =>
      props.$statusReturn ? '1px solid #212121' : '1px solid #dbdbdb'}
  }

  @media (min-width: 575px) {
    height: 96px;
    margin-bottom: 0px;
    border:${(props) =>
      props.$statusReturn ? '1px solid #212121' : '1px solid #dbdbdb'}
  }

`;
export const Card = styled.div`
  width: 100%;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${layoutSize3};
  margin-top: ${layoutSize1};
  gap: ${layoutSize2};
`;
export const ContentTitle = styled.div`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size4};
  font-style: normal;
  font-weight: 700;
  line-height: ${lineHeight1};
  @media (max-width: ${xsMax}) {
    line-height: normal;
  }
`;
export const CardOneContainer = styled.div``;
export const StepOneContent = styled.div`
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
  color: ${gray9};
`;

export const Label = styled.p`
  position: absolute;
  top: ${layoutSize2};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
  color: ${gray7};
`;
export const StepThreeContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${layoutSize3};
  margin-top: ${layoutSize1};
  width: auto;
  gap: ${layoutSize2};
`;

export const StepThreeContentText = styled.span`
  color: ${gray9};
  font-family: ${family0};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
  margin-top: ${layoutSize2};
`;
export const StepTwoTitle = styled.div`
  font-weight: 400;
  font-size: ${size2};
  line-height: ${lineHeight3};
  color: ${gray6};
  margin-top: ${layoutSize2};
  margin-bottom: ${layoutSize2};
`;
export const ButtonCantainer = styled.div`
  margin-top: ${layoutSize2};
`;
export const StepContentTitle = styled.div`
  color: ${gray7};
  font-family: ${family0};
  font-size: ${size4};
  font-style: normal;
  font-weight: 700;
  line-height: ${lineHeight1};
  @media (max-width: ${xsMax}) {
    line-height: normal;
  }
`;
export const StepThreeText = styled.div`
  color: ${gray7};
  font-family: ${family0};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
`;
export const SubmitBlock = styled.div`
  display: flex;
  gap: ${layoutSize4};
  margin-top: 61px;
  margin-bottom: 29px;
  @media (max-width: ${xsMax}) {
    > button {
      width: 100%;
    }
  }
`;

export const PaperCardContainer = styled.div`
  padding: ${layoutSize8};
  border-radius: ${cornerRadius4};
  border: 1px solid ${gray6};
  background: ${white};
`;
export const PaperCardTitle = styled.p`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size5};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight5};
  margin-bottom: ${layoutSize3};
`;

export const InsideCardOne = styled.div`
  display: flex;
  gap: ${layoutSize4};
  padding-top: ${layoutSize6};
  padding-bottom: ${layoutSize4};
`;
export const ResumePlanTitle = styled.p`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size3};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
  margin-bottom: ${layoutSize1};
`;
export const InsideCardTwo = styled.div`
  display: flex;
  gap: ${layoutSize4};
  padding-top: ${layoutSize6};
  padding-top: ${layoutSize4};
`;
export const ResumeLink = styled.div`
  display: inline;
  gap: 4px;
  align-items: center;
`;
