import { Button, Modal } from '@veneer/core';
import React, { useEffect, useState } from 'react';

import { ModalButton } from './Style';
const PaperErrorModal = ({ modalOpen, openSecond, isLoading, t, onClose }) => {
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    setOpen(modalOpen);
  }, [modalOpen]);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  return (
    <>
      <div style={{ position: 'relative' }}>
        <Modal
          align="start"
          id="modal-id"
          closeButton
          show={open}
          onClose={handleClose}
          footer={
            <ModalButton>
              <Button
                data-testid="Try it again"
                appearance="secondary"
                onClick={openSecond}
                loading={isLoading}
              >
                {t('Paper.FirstComponent.error-tryAgain', 'Try it again')}
              </Button>
            </ModalButton>
          }
          title={t('Paper.FirstComponent.error-title', 'Something went wrong')}
        >
          <p>
            {t(
              'Paper.FirstComponent.error-description',
              'It has not been possible to remove the  Paper Add-on Service from your plan.'
            )}
          </p>
        </Modal>
      </div>
    </>
  );
};

export default PaperErrorModal;
