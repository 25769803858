import React, { useEffect } from 'react';
import { captureClickEvents } from '../../../../services/DataCollection/clickActions';
import { AnalyticsClickEventsList, PeriodType } from '../../../../constants';
import { IconReceiptPrinter } from '@veneer/core';
import { redirectURLs } from 'src/components/configs/external-link';
import {
  Content,
  SubText,
  CancelModalCardLabel,
  CancelModalIcon,
  ModalContentLabel,
  CancelModalCardTitle,
  CardTitleAlignment,
  ModalInformation,
  Link,
  CancellationModal
} from './Styles';
import { CancelModalFeeCard } from './CancelModalFeeCard';

const CancelationModal = ({ t, props }) => {
  const [open, setOpen] = React.useState(false);
  const getstandardYearFeeCost = (optionName) => {
    const option = props?.subscriptionOption?.subscription?.cancellation?.find(
      (option) => option.name === optionName
    );

    return option?.outcomes[0]?.value?.cost;
  };

  const standardYear1FeeCost = getstandardYearFeeCost(
    PeriodType.STANDARD_YEAR1
  );
  const standardYear2FeeCost = getstandardYearFeeCost(
    PeriodType.STANDARD_YEAR2
  );

  useEffect(() => {
    if (open) {
      captureClickEvents(
        AnalyticsClickEventsList.CANCELMODAL,
        props?.printerSubscription?.entityStartDate,
        props?.printerSubscription?.subscriptionId,
        props?.cancellationPeriod
      );
    }
  }, [
    props?.printerSubscription?.subscriptionId,
    props?.printerSubscription?.entityStartDate,
    open,
    props?.cancellationPeriod
  ]);

  return (
    <>
      <a
        href="#"
        data-testid="modal-close-button"
        // onClick={() => setOpen(true)}
        onClick={() => {
          setOpen(true);
          captureClickEvents(
            AnalyticsClickEventsList.CANCELLATION_FEE_LINK,
            props?.printerSubscription?.entityStartDate,
            props?.printerSubscription?.subscriptionId,
            props?.cancellationPeriod
          );
        }}
      >
        <CancelModalCardLabel>
          {t('CancelModal.more-info2', 'cancellation fees.')}
        </CancelModalCardLabel>
      </a>

      <CancellationModal
        align="start"
        closeButton
        id="modal-id"
        show={open}
        onClose={() => setOpen(false)}
      >
        <Content>
          <CardTitleAlignment>
            <CancelModalIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
              >
                <circle
                  opacity="0.1"
                  cx="17.5"
                  cy="17.5"
                  r="17.5"
                  fill="#0076AD"
                />
              </svg>
              <IconReceiptPrinter
                size={35}
                style={{ position: 'absolute', top: '10px', left: '8px' }}
              />
            </CancelModalIcon>
            <CancelModalCardTitle>
              {t('CancelModal.title', 'Cancellation Fees')}
            </CancelModalCardTitle>
          </CardTitleAlignment>

          <SubText>
            {t(
              'CancelModal.subtext',
              'The cancellation fees listed below are calculated from the date of enrollment:'
            )}
          </SubText>
          <CancelModalFeeCard
            t={t}
            standardYear1FeeCost={standardYear1FeeCost}
            standardYear2FeeCost={standardYear2FeeCost}
            cancelOptionName={props?.cancelOptionName}
          />
        </Content>

        <ModalContentLabel>
          *{t('CancelModal.ModalContent4', 'Taxes may be applied.')}
        </ModalContentLabel>
        <ModalInformation>
          {t('CancelModal.More-Information', 'For more information, visit ')}
          <Link
            href={redirectURLs.termsofService}
            target="blank"
          >
            {' '}
            {t('CancelModal.TermsOfServices', 'Terms of Services')}
          </Link>
        </ModalInformation>
      </CancellationModal>
    </>
  );
};

export default CancelationModal;
