import styled from 'styled-components';
import tokens from '@veneer/tokens';
const { white, gray12 } = tokens.color;
const { size7, size3, lineHeight7, lineHeight3, family0 } = tokens.typography;
const {
  cornerRadius4,
  size5: layoutSize5,
  size6: layoutSize6,
  size4: layoutSize4
} = tokens.layout;

export const Container = styled.section`
  margin-top: 38px;
  margin-right: ${layoutSize6};
  margin-left: 51px;
  padding: ${layoutSize5};
  background: ${white};
  border-radius: ${cornerRadius4};
`;
export const CardTitle = styled.div`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size7};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight7};
  margin-bottom: ${layoutSize5};
`;
export const CardText = styled.div`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size3};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
  margin-bottom: 40px;
`;
export const Line = styled.div`
  border-top: 1px solid #dbdbdb;
  margin-top: 40px;
`;
export const InsideCard = styled.div`
  display: flex;
  gap: ${layoutSize4};
  padding-top: ${layoutSize5};
`;
