import React, { useEffect, useMemo } from 'react';
import {
  ReactiveSubscriptionButtonsContainer,
  ReactiveSubscriptionCardText
} from './Style';
import { Button, Modal } from '@veneer/core';
import { internalURLs } from 'src/components/configs/internal-link';
import { SubscriptionsClient } from 'src/api/SubscriptionClient';
import { getPayloadWithOptionId } from 'src/types/subscription';
import { TranslatorFunctionType } from 'src/types/localization';
import { JarvisAuthProvider } from '@jarvis/web-http';
import { Stack } from 'src/types/stratus';
import { CancelState } from 'src/types/initialCancelState';
import useModifiedUrl from 'src/hooks/useModifiedUrl';
type NavigationType = {
  push: (url: string) => void;
};

interface ReactiveSubscriptionModalProps {
  t: TranslatorFunctionType;
  link: string;
  navigation?: NavigationType;
  props?: CancelState;
  authProvider?: JarvisAuthProvider;
  stack?: Stack;
  optionId?: string;
  bizlogicId?: string;
}

const ReactiveSubscriptionModal: React.FC<ReactiveSubscriptionModalProps> = ({
  t,
  link,
  navigation,
  props,
  authProvider,
  stack,
  optionId,
  bizlogicId
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenTryModal, setIsOpenTryModal] = React.useState(false);
  const [isReactive, setIsreactive] = React.useState(false);

  const subscriptionClient = useMemo(
    () => new SubscriptionsClient(authProvider, stack, bizlogicId),
    [authProvider, stack, bizlogicId]
  );

  const updateSubscription = async () => {
    try {
      await subscriptionClient.updateSubscription(
        props?.printerSubscription?.subscriptionId,
        getPayloadWithOptionId(optionId) //  Replace '12345' with the real optionId
      );
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };
  const finalPath = useModifiedUrl(internalURLs.PrinterOverviewURL);
  const reactiveSubscription = async () => {
    setIsreactive(true);
    const success = await updateSubscription();
    if (success) {
      navigation.push(`${finalPath}?rescindCanel=success`);
      setIsOpen(false);
      setIsOpenTryModal(false);
    } else {
      setIsOpenTryModal(true);
      setIsreactive(false);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen || isOpenTryModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen, isOpenTryModal]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const rescindCancel = params.get('rescindCancel');
    if (rescindCancel === 'true') {
      setIsOpen(true);
      const newUrl = window.location.pathname;
      if (params.has('rescindCancel')) {
        params.delete('rescindCancel');
      }
      window.history.replaceState({}, '', newUrl);
    }
  }, []);
  return (
    <>
      <a
        href="#"
        data-testid="modal-close-button"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <ReactiveSubscriptionCardText>{link}</ReactiveSubscriptionCardText>
      </a>

      <Modal
        align="start"
        closeButton={false}
        show={isOpen}
        onClose={() => setIsOpen(false)}
        data-testid="modal-content1"
        footer={
          <ReactiveSubscriptionButtonsContainer>
            <Button
              appearance="secondary"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t(
                'SecondComponent.ReactiveSubscription.modal.button-back',
                'No, Thanks'
              )}
            </Button>
            <Button
              data-testid="resume-button"
              appearance="primary"
              onClick={reactiveSubscription}
              loading={isReactive}
            >
              {t(
                'SecondComponent.ReactiveSubscription.modal.button-reactivate',
                'Resume'
              )}
            </Button>
          </ReactiveSubscriptionButtonsContainer>
        }
        title={t(
          'SecondComponent.ReactiveSubscription.modal.title',
          'Confirm HP All-In Plan resume'
        )}
      >
        <p>
          {t(
            'SecondComponent.ReactiveSubscription.modal.subtitle',
            'Do you want to resume your HP All-In Plan subscription?'
          )}
        </p>
      </Modal>

      <Modal
        align="start"
        closeButton={true}
        show={isOpenTryModal}
        onClose={() => setIsOpenTryModal(false)}
        data-testid="modal-content2"
        footer={
          <ReactiveSubscriptionButtonsContainer>
            <Button
              appearance="secondary"
              onClick={reactiveSubscription}
            >
              {t(
                'SecondComponent.ReactiveSubscription.modal.button-Try_it_again',
                'Try it again'
              )}
            </Button>
          </ReactiveSubscriptionButtonsContainer>
        }
        title={t(
          'SecondComponent.ReactiveSubscription.modal.api_failure_title',
          'Something went wrong'
        )}
      >
        <p>
          {t(
            'SecondComponent.ReactiveSubscription.modal.api_failure_content',
            'The reactivation of your subscription could not be carried out.'
          )}
        </p>
      </Modal>
    </>
  );
};

export default ReactiveSubscriptionModal;
