import {
  Stack,
  StratusClient
} from '@jarvis/web-stratus-client/dist/src/clients/stratus-client';
import { JarvisAuthProvider } from '@jarvis/web-http';
import { AxiosResponse } from 'axios';
import { PaginatedOrders, Order } from '../../types/order';
//import { reloginOnUnauthorized } from '../../utils/reloginOnUnauthorized';
import { URLS } from '../urlConfig';

export class FulfillmentClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(URLS[stack].API_URLS.FULFILLMENT_URL, authToken);
  }

  async getFulfilmentOrderByFulfillmentOrderId(
    fulfillmentOrderId: string,
    subscriptionId?: string,
    productType?: string
  ): Promise<Order[]> {
    let returnData: Order[] = [];
    let nextPage = 1;
    try {
      do {
        const { data: pageData } = (await this.jarvisWebHttpInstance.get({
          url: '/orders',
          params: {
            fulfillmentOrderId,
            subscriptionId,
            productType,
            page: nextPage,
            size: 20,
            sort: 'createdAt,desc'
          }
        })) as AxiosResponse<PaginatedOrders>;

        returnData = [...returnData, ...pageData.content];

        nextPage = pageData.totalPages !== nextPage ? nextPage + 1 : 0;
      } while (nextPage > 0);
    } catch (err) {
      //reloginOnUnauthorized(err);
      console.log('Error :', err);
      throw err;
    }
    return returnData;
  }
}
