import React from 'react';
import { CancellationText, LinkText } from '../styles';
import { redirectURLs } from 'src/components/configs/external-link';

const DeviceNotShippedContent = ({ t }) => (
  <CancellationText data-testid="deviceNotShipped">
    {t(
      'FirstComponent.Description.Content9',
      'The HP All-In Plan Printer is on the way. If you cancel now you will need to return the device to us via FEDEX under a 10 day period after receiving the device to avoid being charge a NON-Return Fee.'
    )}
    <CancellationText margin="0">
      {t('FirstComponent.Description.Content10', 'For more information please')}{' '}
      <LinkText
        target="_blank"
        href={redirectURLs.hpsupportContactURL}
      >
        {t('FirstComponent.Description.Content11', 'contact support')}
      </LinkText>
    </CancellationText>
  </CancellationText>
);

export default DeviceNotShippedContent;
