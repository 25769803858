import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CancellationRemorseContent from '../CancellationRemorseContent';
import CancellationNonRemorseContent from '../CancellationNonRemorseContent';
import DeviceNotShippedContent from '../DeviceNotShippedContent';

const CancellationInfoText = ({ t, props, cancelationModal }) => {
  const { enableBuyerRemorseCancellation } = useFlags();

  if (props?.isDeviceShipped) {
    return (
      <>
        {props?.cancellationRemorse ? (
          <CancellationRemorseContent
            t={t}
            props={props}
            cancelationModal={cancelationModal}
          />
        ) : (
          <CancellationNonRemorseContent
            t={t}
            props={props}
            cancelationModal={cancelationModal}
          />
        )}
      </>
    );
  }

  return (
    !props?.isDeviceShipped &&
    enableBuyerRemorseCancellation && <DeviceNotShippedContent t={t} />
  );
};

export default CancellationInfoText;
