import { useEffect, useState } from 'react';
import { SubscriptionsStateClient } from 'src/services/SubscriptionsStateClient';

const useSubscriptionDataFetcher = (authProvider, stack, subscriptionId) => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [subSuccess, setSubSuccess] = useState(false);
  const [subError, setSubError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const subscriptionsClient = new SubscriptionsStateClient(
        authProvider,
        stack
      );

      try {
        const { data } = await subscriptionsClient.getSubscriptionById(
          subscriptionId
        );
        setSubscriptionData(data);
        setSubSuccess(true);
        setSubError(false);
      } catch (e) {
        setSubError(true);
        setSubSuccess(false);
        console.log('Error', e);
      }
    }
    subscriptionId && fetchData();
  }, [authProvider, stack, subscriptionId]);

  return { subscriptionData, subSuccess, subError };
};

export default useSubscriptionDataFetcher;
