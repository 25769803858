import styled from 'styled-components';
import { tokens } from '@veneer/tokens';
const { hpBlue7, black } = tokens.color;
const { size5, size2, size3, lineHeight3, lineHeight5, family0 } =
  tokens.typography;
export const RecyclingModalContent = styled.div`
  margin-bottom: 20px;
`;

export const RecyclingModalCardLabel = styled.span`
  color: ${hpBlue7};
  font-family: ${family0};
  font-size: ${size3};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
  cursor: pointer;
  text-decoration: underline;
  margin-left: 4px;
  margin-right: 4px;
`;

export const RecyclingModalCardTitle = styled.span`
  color: ${black};
  margin-bottom: 8px;
  font-family: ${family0};
  font-size: ${size5};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight5};
  margin-left: 20px;
`;
export const RecyclingModalIcon = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  gap: 3px;
`;
export const RecyclingModalCardContent = styled.p`
  color: ${black};
  font-family: ${family0};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
`;
