import React from 'react';
import { CissPrinter } from './CissPrinter';
import { NonCissPrinter } from './NonCissPrinter';

const ReturnDevice = ({ t, isInRemorse, props }) => {
  return (
    <>
      {props?.isCissPrinter ? (
        <CissPrinter
          isInRemorse={isInRemorse}
          t={t}
          props={props}
        />
      ) : (
        <NonCissPrinter
          isInRemorse={isInRemorse}
          t={t}
          props={props}
        />
      )}
    </>
  );
};

export default ReturnDevice;
