export function paperRevertSubscription(
  optionsData,
  instantPaperEntityDetails
) {
  const optionCancellationData = optionsData?.entities?.filter(
    (item) => item?.entityId === instantPaperEntityDetails?.entityId
  );
  // Filter the cancellation array based on the conditions
  const filteredCancellations = optionCancellationData[0]?.cancellation.filter(
    (cancellation) => {
      return (
        cancellation?.details?.productType === 'instantPaper' &&
        cancellation?.details?.action === 'rescindCancel' &&
        cancellation?.enabled === true
      );
    }
  );
  // Return the result based on the filtered data
  return filteredCancellations?.length === 0
    ? { error: true, period: [] }
    : { error: false, period: filteredCancellations };
}
