import React from 'react';
import { captureClickEvents } from '../../../../services/DataCollection/clickActions';
import { AnalyticsClickEventsList } from '../../../../constants';
import { CardLabel, CardText } from '../../FirstComponent/Style';
import { IconHeadset } from '@veneer/core';
import { redirectURLs } from '../../../configs/external-link';

const CustomerSupport = ({ t, props, captureEvent }) => {
  let period;
  if (props?.cancellationPeriod?.length > 0) {
    period = props?.cancellationPeriod;
  } else {
    period = false;
  }
  return (
    <>
      <IconHeadset size={40} />
      <div>
        <CardLabel>
          {t('CustomerSupport.title', 'Have questions or need help?')}
        </CardLabel>
        <a
          href={redirectURLs.hpsupportContactURL}
          target="_blank"
          rel="noreferrer"
          data-testid="Support"
          onClick={() => {
            if (captureEvent === 'SupportFirst') {
              captureClickEvents(
                AnalyticsClickEventsList.SUPPORT_FIRST,
                props?.printerSubscription?.entityStartDate,
                props?.printerSubscription?.subscriptionId,
                period
              );
            } else {
              captureClickEvents(
                AnalyticsClickEventsList.SUPPORT_SECOND,
                props?.printerSubscription?.entityStartDate,
                props?.printerSubscription?.subscriptionId,
                period
              );
            }
          }}
        >
          <CardText>
            {t('CustomerSupport.subtitle', 'Contact our 24/7 Pro live support')}
          </CardText>
        </a>
      </div>
    </>
  );
};

export default CustomerSupport;
