import { InkPlanInfo } from './planInfo';
import { Cancellation, SubscriptionOptionsResponse } from './subscription';
import { Entity, PrinterDetails } from './subscriptionState';

export interface CancelState {
  parentSKU: string | null;
  productSKU: string | null;
  instantInkProductSKU: string | null;
  printerDetails: PrinterDetails | null;
  printerSubscription: Entity | null;
  subscriptionOption: SubscriptionOptionsResponse | null;
  cancellationPeriod: Cancellation | null;
  cancellationRemorse: boolean | null;
  cancellationBillingDate: any | null;
  cancellationInvoicedDate: any | null;
  cancellationPeriodCost: string | null;
  openSecond: boolean | null;
  openSurvey: boolean | null;
  triggerCancel: boolean | null;
  cancelOptionName: string | null;
  isDeviceShipped: any | null;
  isCissPrinter: boolean | null;
  inkPlan: InkPlanInfo | null;
  instantPaperEntityDetails: Entity | null;
  instantPaperPriceDetails: string | null;
  directlyLoadSecondComponent: boolean | null;
}

export const initialCancelState: CancelState = {
  parentSKU: null,
  productSKU: null,
  instantInkProductSKU: null,
  printerDetails: null,
  printerSubscription: null,
  subscriptionOption: null,
  cancellationPeriod: null,
  cancellationRemorse: null,
  cancellationBillingDate: null,
  cancellationInvoicedDate: null,
  cancellationPeriodCost: null,
  openSecond: false,
  openSurvey: false,
  triggerCancel: false,
  cancelOptionName: null,
  isDeviceShipped: null,
  isCissPrinter: false,
  inkPlan: null,
  instantPaperEntityDetails: null,
  instantPaperPriceDetails: null,
  directlyLoadSecondComponent: false
};
