import { JarvisAuthProvider } from '@jarvis/web-http';
import {
  Stack,
  StratusClient
} from '@jarvis/web-stratus-client/dist/src/clients/stratus-client';
import { Address } from '../../types/address';
import { URLS } from '../urlConfig';

export class AddressStateClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(URLS[stack].API_URLS.ADDRESS_URL, authToken);
  }

  async getAddressById(tenantId: string): Promise<Address> {
    if (!tenantId) return;
    return this.jarvisWebHttpInstance
      .get({ url: `/addresses/${tenantId}` })
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  }
}
